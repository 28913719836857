import axios from "axios";
import authHeader from "./auth-header";
import { APP_URL } from "./http-common";

const URLEntityName = APP_URL + "company-license";

// const getAllCompanyLicense = () => {
//   return axios
//     .get(URLEntityName + "", {
//       headers: authHeader(),
//     })
//     .then((response) => {
//       if (response.data) {
//       }
//       return response.data;
//     });
// };

const getAllCompanyLicense = (page, searchValue, limit) => {
  var valueFormSubmission = "";
  var pageValue = page;

  // if (searchValue?.text) {
  //   valueFormSubmission = "&search=" + searchValue.text;
  // }

  if (searchValue?.company) {
    valueFormSubmission = "&search=company=" + searchValue.company;
  }

  if (searchValue?.active) {
    valueFormSubmission = "&search=active=" + searchValue.active;
  }

  if (searchValue?.active && searchValue?.company) {
    valueFormSubmission =
      "&search=active=" +
      searchValue.active +
      "%26company=" +
      searchValue?.company;
  }

  var valueLimit = "";
  if (limit !== "" && limit !== undefined) {
    valueLimit = "&size=" + limit;
  }
  return axios
    .get(
      URLEntityName + "?page=" + pageValue + valueFormSubmission + valueLimit,
      { headers: authHeader() }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};

async function createCompanyLicense(companyLicense) {
  await axios
    .post(URLEntityName, companyLicense, {
      headers: authHeader(),
    })
    .then((response) => {
      return response;
    });
}

async function updateCompanyLicense(companyLicense) {
  await axios
    .put(URLEntityName + "/" + companyLicense.id, companyLicense, {
      headers: authHeader(),
    })
    .then((response) => {
      return response;
    });
}

async function deleteCompanyLicense(id) {
  await axios
    .delete(URLEntityName + "/" + id, { headers: authHeader() })
    .then((response) => {
      if (
        response.status === 200 ||
        response.status === 201 ||
        response.status === 204
      ) {
        return response;
      }
      //return response;
    })
    .catch((err) => {
      return err;
    });
}

const companyLicenseService = {
  getAllCompanyLicense,
  createCompanyLicense,
  updateCompanyLicense,
  deleteCompanyLicense,
};
export default companyLicenseService;
