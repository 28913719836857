import axios from "axios";
import authHeader from "./auth-header";
import { APP_URL } from "./http-common";

const URLEntityName = APP_URL + "product";

const getAllProduct = () => {
  return axios
    .get(URLEntityName + "/all", {
      headers: authHeader(),
    })
    .then((response) => {
      if (response.data) {
      }
      return response.data;
    });
};

const getProduct = (page, searchValue) => {
  var valueProduct = "";
  if (searchValue !== "") {
    valueProduct = "&search=name=" + searchValue;
  }
  return axios
    .get(URLEntityName + "?page=" + page + valueProduct, {
      headers: authHeader(),
    })
    .then((response) => {
      if (response.data) {
      }
      return response.data;
    });
};

async function createProduct(product) {
  await axios
    .post(URLEntityName, product, {
      headers: authHeader(),
    })
    .then((response) => {
      return response;
    });
}

async function updateProduct(product) {
  await axios
    .put(URLEntityName + "/" + product.id, product, {
      headers: authHeader(),
    })
    .then((response) => {
      return response;
    });
}

async function createPriceProduct(price) {
  await axios
    .post(URLEntityName + "/price", price, {
      headers: authHeader(),
    })
    .then((response) => {
      return response;
    });
}

const getAllPrices = (page, searchValue, status) => {
  var valuePrice = "";
  var valueStatus = "";
  if (searchValue !== "") {
    valuePrice = "&search=product_name=" + searchValue;
  }
  if (status === "all") {
    valueStatus = "";
  }
  if (searchValue === "" && status !== "all") {
    valueStatus = "&search=status=" + status;
  }
  if (searchValue !== "" && status !== "all") {
    valueStatus = "%26status=" + status;
  }
  return axios
    .get(
      URLEntityName + "/price" + "?page=" + page + valuePrice + valueStatus,
      {
        headers: authHeader(),
      }
    )
    .then((response) => {
      if (response.data) {
      }
      return response.data;
    });
};

async function approvePrice(product) {
  await axios
    .put(URLEntityName + "/price/" + product.id, product, {
      headers: authHeader(),
    })
    .then((response) => {
      return response;
    });
}

async function deleteProduct(id) {
  await axios
    .delete(URLEntityName + "/" + id, { headers: authHeader() })
    .then((response) => {
      if (
        response.status === 200 ||
        response.status === 201 ||
        response.status === 204
      ) {
        return response;
      }
      //return response;
    })
    .catch((err) => {
      return err;
    });
}

async function deletePrice(id) {
  await axios
    .delete(URLEntityName + "/price/" + id, { headers: authHeader() })
    .then((response) => {
      if (
        response.status === 200 ||
        response.status === 201 ||
        response.status === 204
      ) {
        return response;
      }
      //return response;
    })
    .catch((err) => {
      return err;
    });
}

const productService = {
  getAllProduct,
  getProduct,
  createProduct,
  createPriceProduct,
  getAllPrices,
  approvePrice,
  updateProduct,
  deleteProduct,
  deletePrice,
};
export default productService;
