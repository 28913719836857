import * as React from "react";
import { TextField, Button, Stack, InputAdornment } from "@mui/material";
import { Delete, Save } from "@mui/icons-material";
import ModalUI from "../UI/ModalUI";
import { useDispatch, useSelector } from "react-redux";
import {
  createProductRange,
  getProductRange,
  updateProductRange,
} from "../../store/productRange";
import { updateFormInputById } from "../../store/formInputes";

const OptionNameModal = (props) => {
  const dispatch = useDispatch();
  const { message } = useSelector((state) => state.message);
  const { currentPage, searchValueCurrent } = useSelector(
    (state) => state.njesiRaportuese
  );
  const { data, isstatusnew, open, allData } = props;
  const [state, setState] = React.useState({
    key: "",
    value: "",
  });
  const [errorValues, setErrorValues] = React.useState([]);
  const [nameExist, setNameExist] = React.useState(false);

  React.useEffect(() => {
    setErrorValues([]);
    setState({
      key: data.key ? data.key : "",
      value: data.value ? data.value : "",
    });
    setNameExist(false);
  }, [data, open]);

  const handleInputChange = (event) => {
    setNameExist(false);
    const { name, value } = event.target;
    setState({ ...state, [name]: value ? value : "" });
  };

  const saveOption = () => {
    var initialError = [];
    Object.keys(state).map(function (key, index) {
      if (state[key] !== "" || Object.keys(state[key]).length !== 0) {
      } else {
        initialError.push(key);
      }
    });

    setErrorValues(initialError);

    var initialErrorTemp = [];
    initialErrorTemp = initialError.filter((item) => item !== "key"); // remove key item

    if (initialErrorTemp.length === 0) {
      if (isstatusnew) {
        const dataList = allData?.properties?.options;
        let exist = false;
        const updatedDataList = dataList.map((obj) => {
          if (obj?.key === state?.value) {
            setNameExist(true);
            exist = true;
            return;
          } else return obj;
        });
        if (!exist) {
          const newDataTemp = {
            ...allData,
            properties: {
              options: [
                ...updatedDataList,
                { key: state.value, value: state.value },
              ],
            },
          };
          const { key, ...newProductRange } = state;
          dispatch(updateFormInputById({ formInput: newDataTemp }));
          props.onGetDataUpdate(newDataTemp);
          props.onClose();
        }
      } else {
        const { ...updateUnitTemp } = state;

        const dataList = allData?.properties?.options;

        const updatedDataList = dataList.map((obj) => {
          if (obj?.key === state?.key) {
            return { ...obj, value: state?.value };
          } else return obj;
        });

        const newDataTempUpdate = {
          ...allData,
          properties: {
            // ...allData?.properties,
            options: updatedDataList,
          },
        };

        // dispatch(
        //   updateProductRange({
        //     productItem: updateUnitTemp,
        //     currentPage: currentPage,
        //     searchValueCurrent: searchValueCurrent,
        //   })
        // );

        dispatch(updateFormInputById({ formInput: newDataTempUpdate }));
        props.onGetDataUpdate(newDataTempUpdate);
        props.onClose();
      }
      //   dispatch(
      //     getProductRange({
      //       page: currentPage,
      //       searchValue: searchValueCurrent,
      //     })
      //   );
    }
  };

  const deleteOption = () => {
    const dataList = allData?.properties?.options;

    const updateDatalist = dataList.filter((obj) => obj.key !== state?.key);
    const newDataTempUpdate = {
      ...allData,
      properties: {
        // ...allData?.properties,
        options: updateDatalist,
      },
    };

    dispatch(updateFormInputById({ formInput: newDataTempUpdate }));
    props.onGetDataUpdate(newDataTempUpdate);
    props.onClose();
    //logic dispatch delete here
  };

  const checkError = (item) => {
    if (errorValues.find((element) => element === item)) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <ModalUI {...props}>
      <Stack spacing={2}>
        <TextField
          name="value"
          autoComplete="off"
          id="value"
          variant="outlined"
          error={checkError("value")}
          helperText={checkError("value") ? "Emri është i detyruar" : null}
          InputProps={{
            startAdornment: (
              <InputAdornment
                sx={{
                  "&.MuiInputAdornment-root": {
                    minWidth: "100px",
                  },
                }}
                position="start"
              >
                Emër
              </InputAdornment>
            ),
          }}
          value={state.value}
          onChange={handleInputChange}
        />
      </Stack>
      <>{nameExist && "Kjo vlere ekziston"}</>
      <Stack sx={{ display: "flex", width: "100%" }}>
        <Button
          variant="contained"
          sx={{
            backgroundColor: props?.delete ? "primary.error" : "primary.dark",
            display: "flex",
            marginTop: "40px",
            ":hover": {
              backgroundColor: props?.delete ? "primary.error" : "primary.dark",
            },
          }}
          startIcon={props?.delete ? <Delete /> : <Save />}
          onClick={props?.delete ? deleteOption : saveOption}
        >
          {isstatusnew ? "Shto" : props?.delete ? "Fshi" : "Ndrysho"}
        </Button>
      </Stack>
    </ModalUI>
  );
};
export default OptionNameModal;
