import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";
import { setLoading } from "./loading";
import CompanyService from "../services/company.service";

const initialState = {
  company: [],
};

export const getCompany = createAsyncThunk(
  "company/getCompany",
  async ({ id }, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    try {
      const data = await CompanyService.getCompanyById(id);
      thunkAPI.dispatch(setLoading(false));
      return { company: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      // thunkAPI.dispatch(setMessage(message));
      // thunkAPI.dispatch(setLoading(false));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updateCompany = createAsyncThunk(
  "company/update",
  async ({ company }, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    try {
      const data = await CompanyService.updateCompany(company);
      //thunkAPI.dispatch(getAll());
      //   thunkAPI.dispatch(getAll({ page: 0, searchValue: "" }));
      //   thunkAPI.dispatch(setLoading(false));
      thunkAPI.dispatch(setLoading(false));
      return thunkAPI.rejectWithValue();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      thunkAPI.dispatch(setLoading(false));
      thunkAPI.dispatch(setMessage({ message: message }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getAllCompany = createAsyncThunk(
  "company/getAllCompany",
  async ({ search }, thunkAPI) => {
    // thunkAPI.dispatch(setMessage(""));
    //thunkAPI.rejectWithValue();
    try {
      const data = await CompanyService.getAllCompany(search);
      //thunkAPI.dispatch(setLoading(false));
      //  thunkAPI.rejectWithValue();
      return {
        company: data,
      };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      // thunkAPI.dispatch(setLoading(false));
      //thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

const companySlice = createSlice({
  name: "company",
  initialState,
  extraReducers: {
    [updateCompany.fulfilled]: (state, action) => {
      state.company = action.payload.company;
    },
    [updateCompany.rejected]: (state, action) => {
      state.company = [];
    },
    [getCompany.fulfilled]: (state, action) => {
      state.company = action.payload.company;
    },
    [getCompany.rejected]: (state, action) => {
      state.company = [];
    },
    [getAllCompany.fulfilled]: (state, action) => {
      state.company = action.payload.company;
    },
    [getAllCompany.rejected]: (state, action) => {
      state.company = [];
    },
  },
});

const { reducer } = companySlice;
export default reducer;
