const gridLabels = {
  noRowsLabel: "Nuk ka rekorde",
  noResultsOverlayLabel: "Nuk u gjet asnje",
  toolbarFilters: "",
  toolbarExport: "",
  toolbarExportCSV: "Eksporto si CSV",
  toolbarExportPrint: "Printo",
  columnMenuHideColumn: "Fshih",
  filterPanelColumns: "Kolonat",
  filterPanelOperators: "Operatori",
  filterPanelInputLabel: "Çmimi",
  filterPanelInputPlaceholder: "Filtro vleren",
  columnMenuUnsort: "Hiq renditje",
  columnMenuSortAsc: "Rendit nga me e madhja",
  columnMenuSortDesc: "Rendit nga me e vogla",
  footerTotalRows: "Rreshta total:",
  filterOperatorContains: "permban",
  filterOperatorEquals: "baraz",
  filterOperatorStartsWith: "fillon me",
  filterOperatorEndsWith: "mbaron me",
  filterOperatorIs: "eshte",
  filterOperatorNot: "nuk eshte",
  filterOperatorAfter: "eshte pas",
  filterOperatorIsEmpty: "eshte bosh",
  filterOperatorIsNotEmpty: "nuk eshte bosh",
};

export default gridLabels;
