import axios from "axios";
import authHeader from "./auth-header";
import { APP_URL } from "./http-common";

const URLEntityName = APP_URL + "company";

const getCompanyById = (id) => {
  return axios
    .get(URLEntityName + "/" + id, { headers: authHeader() })
    .then((response) => {
      return response.data;
    });
};

async function updateCompany(company) {
  await axios
    .put(URLEntityName + "/" + company.id, company, {
      headers: authHeader(),
    })
    .then((response) => {
      return response;
    });
}

const getAllCompany = (searchparam) => {
  var paramsValue = "";
  if (searchparam !== "") {
    paramsValue = "?search=" + searchparam;
  }
  return axios
    .get(URLEntityName + "/all" + paramsValue, {
      headers: authHeader(),
    })
    .then((response) => {
      if (response.data) {
      }
      return response.data;
    });
};

const CompanyService = {
  updateCompany,
  getCompanyById,
  getAllCompany,
};
export default CompanyService;
