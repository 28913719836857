import * as React from "react";
import {
  Autocomplete,
  autocompleteClasses,
  ClickAwayListener,
  InputAdornment,
  MenuItem,
  Popper,
  styled,
  TextField,
  Tooltip,
} from "@mui/material";
import { Box } from "@mui/system";
import PropTypes from "prop-types";
import CorporateFareOutlinedIcon from "@mui/icons-material/CorporateFareOutlined";

const SelectFilter = (props) => {
  const idSelect = props.open ? "github-label" : undefined;
  return (
    <Box sx={{ width: "100%" }}>
      <Tooltip
        title={
          props?.value
            ? props?.source?.find((item) => item.id === props.value)?.name
            : ""
        }
        arrow
      >
        <TextField
          select
          error={props.error}
          helperText={props.helperText}
          disabled
          name={props.name}
          autoComplete="on"
          id={props.id}
          variant="outlined"
          sx={{
            minWidth: props.maxWidth ? props.maxWidth : "250px",
            maxWidth: props.maxWidth ? props.maxWidth : "250px",
            height: "50px",

            "& .Mui-disabled": {
              "fieldset ": {
                // borderColor: "#72aeff !important",
                maxWidth: props.maxWidth ? props.maxWidth : "250px",
              },
            },
            "& .MuiInputBase-root.MuiOutlinedInput-root": {
              backgroundColor: props.disabledfilter ? "#f5f8fc" : "#f6f8fc",
              borderRadius: "12px",
              height: "50px",
            },
            "& .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input.Mui-disabled":
              {
                WebkitTextFillColor: props.disabledfilter
                  ? "#a0a7b4 !important"
                  : "#091B3D !important",
              },
          }}
          InputProps={{
            startAdornment: !props.textfield ? (
              <InputAdornment position="start">
                {props?.label ? props?.label : ""}
              </InputAdornment>
            ) : null,
          }}
          value={props?.value}
          onClick={!props?.disabledfilter ? props?.onClick : null}
        >
          {props?.source ? (
            props?.source?.map((item) => (
              <MenuItem
                key={item?.id}
                value={item?.id}
                name={item?.name ? item?.name : item?.label}
              >
                {item?.name ? item?.name : item?.label}
              </MenuItem>
            ))
          ) : (
            <MenuItem key={1} name={1}>
              {"Ska te dhena"}
            </MenuItem>
          )}
        </TextField>
      </Tooltip>

      <StyledPopper
        id={idSelect}
        open={props.open}
        anchorEl={props.anchorEl}
        sx={{ zIndex: props?.zIndexPopper ? props?.zIndexPopper : 1 }}
        placement="bottom-start"
      >
        <ClickAwayListener onClickAway={props.onClickAway}>
          <div>
            <Autocomplete
              open
              onClose={props.onClose}
              value={props.value}
              onChange={props.onChange}
              disableCloseOnSelect
              PopperComponent={PopperComponent}
              renderTags={() => null}
              noOptionsText={props.noOptionsText}
              renderOption={(props, option) => (
                <li {...props}>
                  <Box sx={{ width: 17, height: 17, mr: "5px", ml: "-2px" }} />
                  <CorporateFareOutlinedIcon></CorporateFareOutlinedIcon>
                  <Box
                    component="span"
                    sx={{
                      width: 14,
                      height: 14,
                      flexShrink: 0,
                      borderRadius: "3px",
                      mr: 1,
                      mt: "2px",
                    }}
                    style={{ backgroundColor: option.color }}
                  />
                  <Box
                    sx={{
                      flexGrow: 1,
                      "& span": {
                        color: "#8b949e",
                      },
                    }}
                  >
                    {option.name ? option.name : option.label}
                  </Box>
                </li>
              )}
              options={props?.source ? props?.source : []}
              getOptionLabel={(option) =>
                option.name ? option.name : option.label || ""
              }
              renderInput={(params) => (
                <TextField
                  sx={{
                    width: "100%",
                    height: "50px",
                    "& .MuiInputBase-root.MuiOutlinedInput-root": {
                      backgroundColor: "white",
                      borderRadius: "12px",
                      height: "50px",
                    },
                  }}
                  ref={params.InputProps.ref}
                  inputProps={params.inputProps}
                  onChange={props.onchangesearch}
                  inputRef={props.inputRef}
                  autoFocus
                  placeholder={props.placeholder}
                />
              )}
            />
          </div>
        </ClickAwayListener>
      </StyledPopper>
    </Box>
  );
};

const StyledAutocompletePopper = styled("div")(({ theme }) => ({
  [`& .${autocompleteClasses.paper}`]: {
    boxShadow: "none",
    margin: 0,
    color: "inherit",
    fontSize: 13,
  },
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    maxHeight: "200px",
    backgroundColor: theme.palette.mode === "light" ? "#fff" : "#1c2128",
    padding: 0,
    [`& .${autocompleteClasses.option}`]: {
      alignItems: "flex-start",
      padding: 8,
      minHeight: "60px",
      height: "auto",
      fontSize: "16px",
      alignItems: "center",
      borderBottom: `1px solid  ${
        theme.palette.mode === "light" ? " #eaecef" : "#30363d"
      }`,
      '&[aria-selected="true"]': {
        backgroundColor: "transparent",
      },
      '&[data-focus="true"], &[data-focus="true"][aria-selected="true"]': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  },
  [`&.${autocompleteClasses.popperDisablePortal}`]: {
    position: "relative",
  },
}));

function PopperComponent(props) {
  const { disablePortal, anchorEl, open, ...other } = props;
  return <StyledAutocompletePopper {...other} />;
}

PopperComponent.propTypes = {
  anchorEl: PropTypes.any,
  disablePortal: PropTypes.bool,
  open: PropTypes.bool.isRequired,
};

const StyledPopper = styled(Popper)(({ theme }) => ({
  zIndex: 1,
  paddingBottom: "20px",
  border: `1px solid ${theme.palette.mode === "light" ? "#e1e4e8" : "#30363d"}`,
  boxShadow: `0 8px 24px ${
    theme.palette.mode === "light" ? "rgba(149, 157, 165, 0.2)" : "rgb(1, 4, 9)"
  }`,
  borderRadius: "15px",
  width: 480,
  fontSize: 13,
  color: theme.palette.mode === "light" ? "#24292e" : "#c9d1d9",
  backgroundColor: theme.palette.mode === "light" ? "#fff" : "#1c2128",
}));

export default SelectFilter;
