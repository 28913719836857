import React from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

export const EditorRichText = (props) => {
  return (
    <div>
      <Editor
        editorState={props?.stateEditor?.editorState}
        wrapperClassName="demo-wrapper"
        editorClassName="demo-editor"
        onEditorStateChange={props.onEditorStateChange}
        editorStyle={{
          width: "100%",
          height: "300px",
          backgroundColor: "#ffffffde",
          paddingLeft: "5px",
          paddingRight: "5px",
        }}
        customStyleMap={{
          HIGHLIGHT: {
            backgroundColor: "#faed27",
          },
        }}
      />
    </div>
  );
};
