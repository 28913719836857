import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Chip, MenuItem, Select, Switch } from "@mui/material";
import gridLabels from "../Labels/GridLabels";
import PRODUKT_COLUMNS from "./ProduktTableColumn";
import ProduktModalEditor from "./ProduktModalEditor";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../store/loading";
import { getAllUnit, updateUnit } from "../../store/njesiRaportuese";
import { Box, fontSize } from "@mui/system";
import RowButtonIcon from "../UI/RowButtonIcon";
import EditIcon from "@mui/icons-material/Edit";
import ButtonAdd from "../UI/ButtonAdd";
import BoxRowButtonsIcon from "../UI/BoxRowButtonsIcon";
import DialogDelete from "../UI/DialogDelete";
import SearchInput from "../UI/SearchInput";
import { CATEGORY } from "../../data/dummy-data";
import { getPrice } from "../../store/product";
import ModalConfrim from "./ModalConfirm";
import { getStatusProductPrice } from "../helper/CustomLabel";
import { STATUS_PRODUCT } from "../../data/dummy-data";

const ProduktTable = () => {
  const dispatch = useDispatch();

  const {
    totalElements,
    totalPages,
    size,
    currentPage,
    searchValueCurrent,
    prices,
  } = useSelector((state) => state.product);
  const user = useSelector((state) => state.auth.user);

  const [open, setOpen] = React.useState(false);
  const [openModalConfrim, setOpenModalConfirm] = React.useState(false);
  const handleClose = () => setOpen(false);
  const handleCloseModalConfirm = () => setOpenModalConfirm(false);
  const [stateDialogDelete, setStateDialogDelete] = React.useState(false);
  const [selectRowId, setSelectRowId] = React.useState("");
  const [selectRowItem, setSelectRowItem] = React.useState({});
  const [isStatusNew, setIsStatusNew] = React.useState(false);
  const [njesi, setNjesi] = React.useState({});
  const [updateNjesi, setUpdateNjesi] = React.useState({});
  const [page, setPage] = React.useState(currentPage);
  const searchState = React.useRef("");
  const [searchValueServer, setSearchValueServer] =
    React.useState(searchValueCurrent);

  const [statusFilter, setStatusFilter] = React.useState("all");
  const initialNjesi = {
    id: "",
    name: "",
    description: "",
  };

  const handleChangePage = (event, newPage) => {
    setPage(event);
  };

  const searchItems = (e) => {
    const searchValue = e.target.value;
    if (searchValue !== "") {
      searchState.current.value = searchValue;
      let filterTimeout;
      filterTimeout = setTimeout(() => {
        setSearchValueServer(searchState.current.value);
      }, 1000);
    } else {
      setSearchValueServer("");
      dispatch(getAllUnit({ page: page, searchValue: "" }));
    }
  };

  React.useEffect(() => {
    getAllData();
  }, [dispatch, page, searchValueServer, statusFilter]);

  const getAllData = () => {
    dispatch(setLoading(true));
    dispatch(
      getPrice({
        page: page,
        searchValue: searchValueServer,
        status: statusFilter,
      })
    )
      .unwrap()
      .then(() => {
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };

  const [filterModel, setFilterModel] = React.useState({
    items: [
      {
        columnField: "",
        operatorValue: ">",
        value: "",
      },
    ],
  });

  const currentlySelected = (e) => {
    setIsStatusNew(false);
    setNjesi(e.row);
    setOpen(true);
    // setOpenModalConfirm(true);
    e.stopPropagation();
  };

  const currentlySelectedUpdateStatus = (e, param) => {
    // setIsStatusNew(false);
    setNjesi(param.row);
    e.stopPropagation();
    setOpenModalConfirm(true);
  };

  const addNew = () => {
    setIsStatusNew(true);
    setNjesi(initialNjesi);
    setOpen(true);
  };

  const handleClickDelete = (e) => {
    setSelectRowId(e.row.id);
    setSelectRowItem(e.row);
    let copyOfObject = { ...e.row };
    delete copyOfObject["status"];
    let tempUpdateNjesi = {
      ...copyOfObject,
      status: e.row?.status === "ACTIVE" ? "INACTIVE" : "ACTIVE",
    };
    setUpdateNjesi(tempUpdateNjesi);
    setStateDialogDelete(true);
  };

  const handleCloseDialogDelete = (e) => {
    setStateDialogDelete(false);
  };
  const handleDeleteNjesiRaportuese = (e) => {
    // dispatch(updateUnit({ unit: updateNjesi }));
    dispatch(
      updateUnit({
        unit: updateNjesi,
        currentPage: currentPage,
        searchValueCurrent: searchValueCurrent,
      })
    ); // update form switch

    // dispatch(deleteUnit({ id: selectRowId }));
    setStateDialogDelete(false);
  };

  const changeStatusFilter = (event) => {
    const { value } = event.target;
    setStatusFilter(value);
  };

  function CustomToolbar() {
    return (
      <Box
        sx={{
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            py: "18px",
            px: "9px",
            alignItems: "center",
            bgcolor: "transparent",
          }}
        >
          <Box
            sx={{
              flexGrow: 1,
              textAlign: "center",
              direction: "column",
              justifyContent: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <SearchInput
              onChange={searchItems}
              inputRef={searchState}
              defaultValue={searchState.current.value}
            ></SearchInput>
          </Box>
          <Select
            value={statusFilter}
            onChange={changeStatusFilter}
            sx={{
              width: "150px",
              height: "40px",
              borderRadius: "5px",
              backgroundColor: "#F6F8FC",
              marginRight: "20px",
            }}
          >
            {[...STATUS_PRODUCT, { label: "Te gjitha", value: "all" }].map(
              (item) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              )
            )}
          </Select>
          {user?.role === "ROLE_OPERATOR" && <ButtonAdd onClick={addNew} />}
        </Box>
      </Box>
    );
  }

  return (
    <div style={{ height: 700, width: "100%" }}>
      <DataGrid
        labelRowsPerPage="Artikuj per faqe"
        disableSelectionOnClick
        getRowId={(row) =>
          row.submittedDate + row.company.id + row.product.name + row.price
        }
        columns={[
          {
            field: "name",
            headerName: "Name",
            minWidth: 250,
            editable: false,
            renderCell: (params) =>
              !params.row.product ? "" : params.row.product.name,
          },
          {
            field: "price",
            headerName: "Çmimi",
            minWidth: 100,
            editable: false,
            flex: 1,
          },
          {
            field: "status",
            headerName: "Status",
            minWidth: 200,
            editable: false,
            // renderCell: (params) =>
            //   params?.row?.status
            //     ? getStatusProductPrice(params?.row?.status)
            //     : "-",
            renderCell: (params) =>
              params?.row?.status ? (
                <ChipStatus
                  status={getStatusProductPrice(params?.row?.status)}
                />
              ) : (
                "-"
              ),

            flex: 1,
          },
          {
            field: "submittedDate",
            headerName: "Data",
            minWidth: 100,
            editable: false,
            flex: 1,
          },
          {
            field: "company",
            headerName: "Kompania",
            minWidth: 250,
            editable: false,
            renderCell: (params) =>
              !params.row.company ? "" : params.row.company.name,
          },
          // {
          //   field: "zona",
          //   headerName: "Zona",
          //   width: 120,
          //   editable: false,
          //   sx: {
          //     "&.MuiDataGrid-root.MuiDataGrid-cell": {
          //       fontSize: "8px",
          //     },
          //   },
          //   renderCell: (params) => (
          //     <span style={{ fontSize: "12px" }}>
          //       {!params.row.company ? "" : params.row?.zona}
          //     </span>
          //   ),
          // },
          // {
          //   field: "id",
          //   headerName: "id",
          //   width: 300,
          //   editable: false,
          //   renderCell: (params) => (!params.row.price ? "" : params.row.price),
          // },

          // ...dataColumns,
          {
            headerName: "",
            overflow: "hidden",
            field: "edit",
            editable: false,
            flex: 1,
            justifyContent: "flex-end",
            disableClickEventBubbling: true,
            align: "right",
            background: "red",
            headers: false,
            renderHeader: () => {},
            renderCell: (params) =>
              params?.row?.status === "PENDING" &&
              user?.role !== "ROLE_OPERATOR" && (
                <BoxRowButtonsIcon>
                  <RowButtonIcon
                    onClick={(event) => {
                      currentlySelectedUpdateStatus(event, params);
                    }}
                  >
                    <EditIcon />
                  </RowButtonIcon>
                  {/* <RowButtonIcon>
                  <Switch
                    checked={params?.value === "ACTIVE"}
                    onClick={(event) => {
                      handleClickDelete(params);
                    }}
                  ></Switch>
                </RowButtonIcon> */}
                </BoxRowButtonsIcon>
              ),
          },
        ]}
        rows={prices ? prices : []}
        density="comfortable"
        editMode="row"
        disableColumnMenu
        localeText={gridLabels}
        componentsProps={{
          pagination: {
            labelRowsPerPage: "Rreshta për Faqe",
            showLastButton: true,
            showFirstButton: true,
          },
        }}
        components={{
          Toolbar: CustomToolbar,
        }}
        onCellClick={currentlySelected}
        filterModel={filterModel}
        onFilterModelChange={(newFilterModel) => setFilterModel(newFilterModel)}
        page={page}
        onPageChange={handleChangePage}
        count={totalPages}
        rowCount={totalElements}
        pageSize={size > 100 ? 100 : size}
        pagination
        paginationMode="server"
      />
      <DialogDelete
        open={stateDialogDelete}
        disagreedelete={handleCloseDialogDelete}
        agreedelete={handleDeleteNjesiRaportuese}
        valueswitch={
          selectRowItem?.status === "ACTIVE"
            ? "INAKTIVE"
            : selectRowItem?.status === "INACTIVE"
            ? "AKTIVE"
            : ""
        }
      />
      <ProduktModalEditor
        open={open}
        onClose={handleClose}
        formname="Produkti"
        isstatusnew={isStatusNew ? 1 : 0}
        data={njesi}
      ></ProduktModalEditor>
      <ModalConfrim
        open={openModalConfrim}
        onClose={handleCloseModalConfirm}
        formname="Status Produkti"
        // isstatusnew={1}
        data={njesi}
      ></ModalConfrim>
    </div>
  );
};

const ChipStatus = (props) => (
  <Chip
    label={props?.status}
    color={
      props?.status === "Konfirmuar"
        ? "success"
        : props?.status === "Refuzuar"
        ? "error"
        : "warning"
    }
    sx={{ fontWeight: "500" }}
    variant="outlined"
  />
);

export default ProduktTable;
