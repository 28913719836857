import CompanyLicenseTable from "../components/CompanyLicense/CompanyLicenseTable";
import StartingPageContent from "../components/StartingPage/StartingPageContent";
const CompanyLicensePage = () => {
  return (
    <StartingPageContent pageName="License Kompanie">
      <CompanyLicenseTable></CompanyLicenseTable>
    </StartingPageContent>
  );
};

export default CompanyLicensePage;
