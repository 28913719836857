import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import DashboardMessageDataService from "../services/dashboardMessage.service";
import { setMessage } from "./message";
import { setLoading } from "./loading";

const initialState = {
  messageMessageContent: "",
};

export const createDashboardMessage = createAsyncThunk(
  "dashboardMessage/createDashboardMessage",
  async ({ messageContent }, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    try {
      const data = await DashboardMessageDataService.createMessage(
        messageContent
      );
      if (data.status === 200) {
        thunkAPI.dispatch(
          setMessage({ message: "Messazhi u dergua me sukses!" })
        );
      }
      thunkAPI.dispatch(setLoading(false));
      return thunkAPI.rejectWithValue();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setLoading(false));
      thunkAPI.dispatch(setMessage({ message: message }));
      return thunkAPI.rejectWithValue();
    }
  }
);

const dashboardMessageDataSlice = createSlice({
  name: "dashboardMessage",
  initialState,
});

const { reducer } = dashboardMessageDataSlice;
export default reducer;
