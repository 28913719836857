import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import gridLabels from "../Labels/GridLabels";
import CompanyLicenseModalEditor from "./CompanyLicenseModalEditor";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../store/loading";
import { Box } from "@mui/system";
import RowButtonIcon from "../UI/RowButtonIcon";
import EditIcon from "@mui/icons-material/Edit";
import ButtonAdd from "../UI/ButtonAdd";
import BoxRowButtonsIcon from "../UI/BoxRowButtonsIcon";
import DialogDelete from "../UI/DialogDelete";
import { getAllLicense, deleteLicense } from "../../store/companyLicense";
import { DeleteOutline } from "@mui/icons-material";
import SelectFilter from "../UI/SelectFilter";
import { getAllCompany } from "../../store/company";
import {
  Button,
  Checkbox,
  FormControlLabel,
  InputLabel,
  Stack,
} from "@mui/material";
import ContainerAnalyst from "../UI/ContainerAnalyst";

const CompanyLicenseTable = () => {
  const dispatch = useDispatch();
  const {
    companyLicense,
    totalElements,
    totalPages,
    size,
    currentPage,
    searchValueCurrent,
  } = useSelector((state) => state.companyLicense);
  const { company } = useSelector((state) => state.company);
  const [open, setOpen] = React.useState(false);
  const [companyState, setCompanyState] = React.useState("");
  const handleClose = () => setOpen(false);
  const [stateDialogDelete, setStateDialogDelete] = React.useState(false);
  const [isStatusNew, setIsStatusNew] = React.useState(false);
  const [njesi, setNjesi] = React.useState({});
  const [dataColumns, setDataColumns] = React.useState([
    {
      field: "code",
      headerName: "Kodi",
      width: 300,
      minWidth: 300,
      flex: 1,
      editable: false,
    },
  ]);
  const [page, setPage] = React.useState(currentPage);
  const searchState = React.useRef("");
  const [searchValueServer, setSearchValueServer] =
    React.useState(searchValueCurrent);
  const [isExporting, setIsExporting] = React.useState(false);
  const [isActive, setIsActive] = React.useState(false);
  const searchStateCompany = React.useRef("");
  const [searchValueServerCompany, setSearchValueServerCompany] =
    React.useState("");
  const [anchorElCompany, setAnchorElCompany] = React.useState(null);
  const openCompany = Boolean(anchorElCompany);

  const initialNjesi = {
    id: "",
    name: "",
  };

  const handleChangePage = (event, newPage) => {
    setPage(event);
  };

  React.useEffect(() => {
    // getAllData();
    getAllLicenseFinal();
  }, [dispatch, page, isActive, companyState]);

  const [filterModel, setFilterModel] = React.useState({
    items: [
      {
        columnField: "",
        operatorValue: ">",
        value: "",
      },
    ],
  });

  const searchItemsCompany = (e) => {
    const searchValue = e.target.value;
    if (searchValue !== "") {
      searchStateCompany.current.value = searchValue;
      let filterTimeout;
      filterTimeout = setTimeout(() => {
        setSearchValueServerCompany(searchStateCompany.current.value);
      }, 1000);
    } else {
      setSearchValueServerCompany("");
      // dispatch(getAllCompany({ search: "" }));
    }
  };

  React.useEffect(() => {
    getAllCompanyData();
  }, [dispatch, searchValueServerCompany]);

  const getAllCompanyData = () => {
    dispatch(setLoading(true));
    dispatch(getAllCompany({ search: searchValueServerCompany }))
      .unwrap()
      .then(() => {
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };

  const currentlySelected = (e) => {
    setIsStatusNew(false);
    setNjesi(e.row);
    setOpen(true);
  };

  const addNew = () => {
    setIsStatusNew(true);
    setNjesi(initialNjesi);
    setOpen(true);
  };

  const handleCloseDialogDelete = (e) => {
    setStateDialogDelete(false);
  };
  const handleDeleteLicense = (e) => {
    dispatch(
      deleteLicense({
        id: njesi.id,
        page: page,
        searchValue: searchValueServer,
      })
    );
    setStateDialogDelete(false);
  };

  const handleClickCompany = (event) => {
    // dispatch(getAllCompany({ search: "" }));
    setAnchorElCompany(event.currentTarget);
  };

  const handleCloseCompany = () => {
    setAnchorElCompany(null);
  };

  const handleChangeIsActive = (event) => {
    setIsActive(event.target?.checked ? event.target.checked : false);
    if (event.target.checked === false) {
      setPage(0);
    }
    // getAllLicenseActive(event.target?.checked ? event.target.checked : false);
  };

  const getAllLicenseFinal = () => {
    dispatch(setLoading(true));
    // dispatch(getAllFormCategory({ page: page, searchValue: searchValueServer }))
    dispatch(
      getAllLicense({
        page: page,
        searchValue: { active: isActive, company: companyState },
      })
    )
      .unwrap()
      .then(() => {
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };

  const handleClearFiltre = (event) => {
    setIsActive(false);
    setCompanyState(null);
    setPage(0);
  };
  return (
    <ContainerAnalyst
      header={
        <Box sx={{ width: "100%", marginRight: "60px", marginTop: "20px" }}>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              alignContent: "flex-start",
              mb: "20px",
            }}
          >
            <Box
              sx={{
                flexGrow: 1,
                direction: "row",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Stack spacing={2} sx={{ mr: "10px", width: "100%" }}>
                <FormControlLabel
                  sx={{ marginRight: "40px", alignSelf: "flex-end" }}
                  value="start"
                  control={
                    <Checkbox
                      name="active"
                      id="active"
                      defaultChecked={false}
                      checked={isActive}
                      onChange={handleChangeIsActive}
                      // {...props}
                    ></Checkbox>
                  }
                  label="Aktive"
                  labelPlacement="start"
                />
              </Stack>
              <InputLabel
                sx={{
                  marginRight: "10px",
                  width: "300px",
                  alignSelf: "center",
                }}
              >
                Kompania
              </InputLabel>
              <Stack spacing={2} sx={{ mr: "10px", width: "100%" }}>
                <SelectFilter
                  textfield
                  name="company"
                  id="company"
                  label="Kompani"
                  placeholder="Kërko Kompani"
                  noOptionsText="Nuk ka Kompani"
                  value={companyState}
                  onClick={handleClickCompany}
                  source={company}
                  open={openCompany}
                  anchorEl={anchorElCompany}
                  onClickAway={handleCloseCompany}
                  onClose={(event, reason) => {
                    if (reason === "escape") {
                      handleCloseCompany();
                    }
                  }}
                  onChange={(event, newValue, reason) => {
                    if (
                      event.type === "keydown" &&
                      event.key === "Backspace" &&
                      reason === "removeOption"
                    ) {
                      return;
                    }
                    setPage(0);
                    // getAllDataCompanyId(newValue.id ? newValue.id : "");
                    setCompanyState(newValue.id ? newValue.id : "");
                    handleCloseCompany();
                    // getAllDataFormUnit();
                  }}
                  maxWidth="100%"
                  onchangesearch={searchItemsCompany}
                  inputRef={searchStateCompany}
                  zIndexPopper={100}
                ></SelectFilter>
              </Stack>
              <Button
                variant="contained"
                sx={{
                  margin: "0px 10px",
                  height: "40px",
                  minWidth: "100px",
                  padding: "0",
                  fontSize: "14px",
                }}
                onClick={handleClearFiltre}
                disableRipple
              >
                Pastro Filtrin
              </Button>
            </Box>
            <ButtonAdd onClick={addNew} />
          </Box>
        </Box>
      }
    >
      <div
        style={{ height: "100%", width: "100%", padding: "0px 20px 0px 20px" }}
      >
        <DataGrid
          labelRowsPerPage="Artikuj per faqe"
          disableSelectionOnClick
          columns={[
            ...dataColumns,
            {
              field: "company",
              headerName: "Kompania",
              maxWidth: 300,
              flex: 1,
              editable: false,
              renderCell: (params) =>
                !params.row?.company ? "" : params.row?.company?.name,
            },
            {
              field: "startDate",
              headerName: "Data",
              width: 100,
              minWidth: 100,
              flex: 1,
              editable: false,
              renderCell: (params) =>
                !params.row?.startDate && !params.row?.endDate
                  ? ""
                  : params.row?.startDate + " : " + params.row?.endDate,
            },
            {
              headerName: "",
              overflow: "hidden",
              field: "status",
              editable: false,
              flex: 1,
              justifyContent: "flex-end",
              disableClickEventBubbling: true,
              align: "right",
              background: "red",
              headers: false,
              renderHeader: () => {},
              renderCell: (params) => (
                <BoxRowButtonsIcon>
                  <RowButtonIcon
                    onClick={(event) => {
                      currentlySelected(params);
                    }}
                  >
                    <EditIcon />
                  </RowButtonIcon>
                  <RowButtonIcon
                    onClick={(event) => {
                      setNjesi(params?.row);
                      setStateDialogDelete(true);
                    }}
                  >
                    <DeleteOutline color="error" />
                  </RowButtonIcon>
                </BoxRowButtonsIcon>
              ),
            },
          ]}
          rows={companyLicense ? companyLicense : []}
          density="comfortable"
          editMode="row"
          disableColumnMenu
          localeText={gridLabels}
          componentsProps={{
            pagination: {
              labelRowsPerPage: "Rreshta për Faqe",
              showLastButton: true,
              showFirstButton: true,
            },
          }}
          // components={{
          //   Toolbar: CustomToolbar,
          // }}
          onCellClick={currentlySelected}
          filterModel={filterModel}
          onFilterModelChange={(newFilterModel) =>
            setFilterModel(newFilterModel)
          }
          page={page}
          onPageChange={handleChangePage}
          count={totalPages}
          rowCount={totalElements}
          pageSize={size > 100 ? 100 : size}
          pagination
          paginationMode="server"
        />
      </div>
      <DialogDelete
        open={stateDialogDelete}
        disagreedelete={handleCloseDialogDelete}
        agreedelete={handleDeleteLicense}
        description="Jeni të sigurt që doni ta fshini këtë Licensë? "
      />
      <CompanyLicenseModalEditor
        open={open}
        onClose={handleClose}
        formname="Licensa"
        isstatusnew={isStatusNew ? 1 : 0}
        data={njesi}
      ></CompanyLicenseModalEditor>
    </ContainerAnalyst>
  );
};

export default CompanyLicenseTable;
