import * as React from "react";
import { Stack, DialogTitle, TextField, Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import ModalUI from "../UI/ModalUI";
import { createDashboardMessage } from "../../store/dashboardMessage";
import { EditorRichText } from "./EditorRichText";
import { EditorState, convertToRaw, ContentState } from "draft-js";

import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { APP_URL } from "../../services/http-common";
import axios from "axios";

const CreateMessageModal = (props) => {
  const dispatch = useDispatch();
  const [stateMessage, setStateMessage] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState(false);
  const [stateEditor, setStateEditor] = React.useState({
    editorState: EditorState.createEmpty(),
  });

  React.useEffect(() => {
    axios.get(APP_URL + "public/dashboard-mesasge").then((response) => {
      const messageTemp = response?.data?.content;
      if (messageTemp !== "" || messageTemp !== " ") {
        const blocksFromHtml = htmlToDraft(messageTemp);
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(
          contentBlocks,
          entityMap
        );
        const messageBackend = EditorState.createWithContent(contentState);
        setStateEditor({ editorState: messageBackend });
      }
    });
  }, []);

  const onEditorStateChange = (editorState) => {
    if (editorState !== "" || editorState == " ") {
      setErrorMessage(false);
      setStateEditor({
        editorState,
      });
    } else {
    }
  };

  const { editorState } = stateEditor;

  const handleSendMessage = () => {
    const tempDataMessage = stateMessage;
    if (
      String(draftToHtml(convertToRaw(editorState.getCurrentContent()))) ==
        "" ||
      String(draftToHtml(convertToRaw(editorState.getCurrentContent()))).trim()
        .length === 0
    ) {
      setErrorMessage(true);
    } else {
      dispatch(
        createDashboardMessage({
          messageContent: String(
            draftToHtml(convertToRaw(editorState.getCurrentContent()))
          ),
        })
      );
      props.onClose();
    }
  };

  const changeMessage = (e) => {
    const commentValue = e.target.value;
    if (commentValue !== "" || commentValue == " ") {
      setErrorMessage(false);
      setStateMessage(e.target.value);
    } else {
    }
  };

  React.useEffect(() => {
    setErrorMessage(false);
    setStateMessage("");
  }, [props.open]);

  return (
    <ModalUI
      width="800px"
      height="100%"
      buttons={
        <Stack sx={{ display: "flex", width: "100%", alignItems: "flex-end" }}>
          <Button
            variant="contained"
            sx={{
              // backgroundColor: "#EF5828",
              width: "160px",
              height: "40px",
              fontSize: "20px",
            }}
            onClick={handleSendMessage}
            autoFocus
          >
            Dërgo
          </Button>
        </Stack>
      }
      {...props}
    >
      <DialogTitle
        id="alert-dialog-title"
        sx={{
          "&.MuiTypography-root.MuiDialogTitle-root": {
            padding: 0,
            textAlign: "center",
          },
          marginTop: "-30px",
          fontSize: "30px",
          color: "#091B3D",
          fontFamily: "Basier Circle Medium",
        }}
      >
        Krijo Mesazh
      </DialogTitle>
      <div style={{ height: 700, width: "100%" }}>
        <EditorRichText
          stateEditor={stateEditor}
          onEditorStateChange={onEditorStateChange}
        />
      </div>
    </ModalUI>
  );
};
export default CreateMessageModal;
