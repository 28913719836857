import axios from "axios";
import authHeader from "./auth-header";
import { APP_URL } from "./http-common";

const URLEntityName = APP_URL + "product-type-range";

const getAllProductRange = () => {
  return axios
    .get(URLEntityName + "/all", {
      headers: authHeader(),
    })
    .then((response) => {
      if (response.data) {
      }
      return response.data;
    });
};

async function createProductRange(productRange) {
  await axios
    .post(URLEntityName, productRange, {
      headers: authHeader(),
    })
    .then((response) => {
      return response;
    });
}

async function updateProductRange(productRange) {
  await axios
    .put(URLEntityName + "/" + productRange.id, productRange, {
      headers: authHeader(),
    })
    .then((response) => {
      return response;
    });
}

async function deleteProductRange(id) {
  await axios
    .delete(URLEntityName + "/" + id, { headers: authHeader() })
    .then((response) => {
      if (
        response.status === 200 ||
        response.status === 201 ||
        response.status === 204
      ) {
        return response;
      }
      //return response;
    })
    .catch((err) => {
      return err;
    });
}

const productRangeService = {
  getAllProductRange,
  createProductRange,
  updateProductRange,
  deleteProductRange,
};
export default productRangeService;
