import * as React from "react";
import { TextField, Button, Stack, InputAdornment } from "@mui/material";
import { Save } from "@mui/icons-material";
import ModalUI from "../UI/ModalUI";
import { useDispatch, useSelector } from "react-redux";
import {
  createProductRange,
  getProductRange,
  updateProductRange,
} from "../../store/productRange";

const ProductRangeEditor = (props) => {
  const dispatch = useDispatch();
  const { currentPage, searchValueCurrent } = useSelector(
    (state) => state.njesiRaportuese
  );
  const { data, isstatusnew, open } = props;
  const [state, setState] = React.useState({
    name: "",
  });
  const [errorValues, setErrorValues] = React.useState([]);

  React.useEffect(() => {
    setErrorValues([]);
    setState({
      id: data.id ? data.id : "",
      name: data.name ? data.name : "",
      status: data.status ? data.status : "ACTIVE",
    });
  }, [data, open]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setState({ ...state, [name]: value ? value : "" });
  };

  const saveUser = () => {
    var initialError = [];
    Object.keys(state).map(function (key, index) {
      if (state[key] !== "" || Object.keys(state[key]).length !== 0) {
      } else {
        initialError.push(key);
      }
    });

    setErrorValues(initialError);

    var initialErrorTemp = [];
    initialErrorTemp = initialError.filter((item) => item !== "id"); // remove id item

    if (initialErrorTemp.length === 0) {
      if (isstatusnew) {
        const { id, ...newProductRange } = state;
        dispatch(createProductRange({ product: newProductRange }));
      } else {
        const { ...updateUnitTemp } = state;
        dispatch(
          updateProductRange({
            productItem: updateUnitTemp,
            currentPage: currentPage,
            searchValueCurrent: searchValueCurrent,
          })
        );
      }
      dispatch(
        getProductRange({
          page: currentPage,
          searchValue: searchValueCurrent,
        })
      );
      props.onClose();
    }
  };

  const checkError = (item) => {
    if (errorValues.find((element) => element === item)) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <ModalUI {...props}>
      <Stack spacing={2}>
        <TextField
          name="name"
          autoComplete="off"
          id="name"
          variant="outlined"
          error={checkError("name")}
          helperText={checkError("name") ? "Emri është i detyruar" : null}
          InputProps={{
            startAdornment: (
              <InputAdornment
                sx={{
                  "&.MuiInputAdornment-root": {
                    minWidth: "100px",
                  },
                }}
                position="start"
              >
                Emër
              </InputAdornment>
            ),
          }}
          value={state.name}
          onChange={handleInputChange}
        />
      </Stack>
      <Stack sx={{ display: "flex", width: "100%" }}>
        <Button
          variant="contained"
          sx={{
            backgroundColor: "primary.dark",
            display: "flex",
            marginTop: "40px",
          }}
          startIcon={<Save />}
          onClick={saveUser}
        >
          {isstatusnew ? "Shto" : "Ndrysho"}
        </Button>
      </Stack>
    </ModalUI>
  );
};
export default ProductRangeEditor;
