import * as React from "react";
import {
  TextField,
  InputLabel,
  Button,
  Stack,
  InputAdornment,
  ButtonBase,
  FormControl,
  Select,
  MenuItem,
  ListItem,
  Chip,
  Box,
} from "@mui/material";
import { Add, Save } from "@mui/icons-material";
import ModalUI from "../UI/ModalUI";
import { useDispatch, useSelector } from "react-redux";
import {
  createFormInputById,
  updateFormInputById,
} from "../../store/formInputes";
import SelectAutocomplate from "../UI/SelectAutocomplate";
import { INDICATOR_TYPE } from "../../data/dummy-data";
import FormListType from "./FormListType";
import OptionNameModal from "./OptionNameModal";

const FormInputEditor = (props) => {
  const dispatch = useDispatch();

  const { data, isstatusnew, formid, open, formInputs } = props;
  const [state, setState] = React.useState({
    id: "",
    formId: formid ? formid : "",
    label: "",
    order: 0,
    defaultAnswer: 0,
    unit: "cope",
    type: "NUMBER",
    properties: { formula: "", options: [] },
  });
  const [errorValues, setErrorValues] = React.useState([]);
  const searchTypeState = React.useRef("");
  const [anchorElType, setAnchorElType] = React.useState(null);
  const [listFormula, setListFormula] = React.useState([]);
  const handleClickType = (event) => {
    setAnchorElType(event.currentTarget);
  };

  const handleCloseType = () => {
    setAnchorElType(null);
  };

  const openType = Boolean(anchorElType);
  const searchItems = (e) => {};
  React.useEffect(() => {
    setState({
      key: data.key ? data.key : "",
      id: data.id ? data.id : "",
      formId: formid ? formid : "",
      label: data.label ? data.label : "",
      order: data.order ? data.order : 0,
      defaultAnswer: data.defaultAnswer ? data.defaultAnswer : 0,
      unit: data.unit ? data.unit : "",
      type: data.type ? data?.type : "",
      properties: data?.properties
        ? {
            formula: data?.properties?.formula ? data?.properties?.formula : "",
            options: data?.properties?.options ? data?.properties?.options : [],
          }
        : {
            options: [],
          },
    });
    setErrorValues([]);

    if (data?.properties?.formula) {
      setListFormula(
        findCommonObjects(extractStrings(data?.properties?.formula, "{", "}"))
      );
    }
  }, [data, open]);

  function extractStrings(mainString, startString, endString) {
    // Create a regular expression pattern with startString and endString as delimiters
    const pattern = new RegExp(`${startString}(.*?)${endString}`, "g");

    // Use match() to find all matches in the mainString
    const matches = mainString.match(pattern);

    // Check if matches were found
    if (matches) {
      // Extract the captured groups from matches
      const result = matches.map((match) =>
        match.replace(startString, "").replace(endString, "")
      );
      return result;
    } else {
      // If no matches were found, return an empty array
      return [];
    }
  }

  function findCommonObjects(listIds = []) {
    const commonObjects = formInputs?.filter((obj) =>
      listIds?.includes(obj.key)
    );
    const newArray = commonObjects.map(({ label, key }) => ({
      label,
      value: key,
    }));
    return newArray;
  }

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    const onlyNums = event.target.value.replace(/[^0-9]/g, "");
    if (name === "order") {
      if (onlyNums) {
        setState({ ...state, [name]: value ? value : "" });
      }
    } else {
      setState({ ...state, [name]: value ? value : "" });
    }
  };

  const [openOption, setOpenOption] = React.useState(false);
  const handleOpenOption = () => setOpenOption(true);
  const handleCloseOption = () => {
    setOpenOption(false);
  };

  const saveFormTemplate = () => {
    var initialError = [];
    Object.keys(state).map(function (key, index) {
      if (state[key] !== "" || Object.keys(state[key]).length !== 0) {
      } else {
        initialError.push(key);
      }
    });

    const initialErrorTemp = initialError.filter(
      (item) => item !== "key" && item !== "id"
    ); // remove key item

    setErrorValues(initialErrorTemp);

    if (initialErrorTemp.length === 0) {
      if (isstatusnew) {
        const { id, ...newFormInput } = state;

        if (state?.type === "FORMULA") {
          const newFormInputList = {
            ...newFormInput,
            properties: {
              formula: createStringBetweenCharacters(listFormula, "value"),
            },
          };
          dispatch(createFormInputById({ formInput: newFormInputList }));
        } else {
          dispatch(createFormInputById({ formInput: newFormInput }));
        }

        // dispatch(createFormInputById({ formInput: newFormInput }));
      } else {
        const { ...updateFormInputTemp } = state;
        if (state?.type === "FORMULA") {
          const newDataTempUpdate = {
            ...updateFormInputTemp,
            properties: {
              formula: createStringBetweenCharacters(listFormula, "value"),
            },
          };
          dispatch(updateFormInputById({ formInput: newDataTempUpdate }));
        } else {
          dispatch(updateFormInputById({ formInput: updateFormInputTemp }));
        }
      }
      props.onClose();
    }
  };

  function createStringBetweenCharacters(list, type = "value") {
    // Check if the list is an array
    if (!Array.isArray(list)) {
      return "Input is not a valid list.";
    }

    // Use map to extract the values and join them between "{ }"
    const resultString =
      type === "value"
        ? "SUM(" + list.map((item) => `{${item.value}}`).join("") + ")"
        : "SUM(" + list.map((item) => `{${item.label}}`).join("") + ")";

    return resultString;
  }

  const checkError = (item) => {
    if (errorValues.find((element) => element === item)) {
      return true;
    } else {
      return false;
    }
  };

  const handleGetDataUpdate = (dataTemp) => {
    setState(dataTemp);
  };

  const DeleteIndicatorFormula = (item) => {
    setListFormula(listFormula.filter((it) => it?.value !== item.value));
  };

  return (
    <ModalUI
      buttons={
        <Stack sx={{ display: "flex", width: "100%" }}>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "primary.dark",
              display: "flex",
              marginTop: "40px",
            }}
            startIcon={<Save />}
            onClick={saveFormTemplate}
          >
            {isstatusnew ? "Shto" : "Ndrysho"}
          </Button>
        </Stack>
      }
      {...props}
    >
      <Stack spacing={2}>
        <TextField
          name="label"
          autoComplete="off"
          id="label"
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">Indikator</InputAdornment>
            ),
          }}
          value={state.label}
          error={checkError("label")}
          helperText={checkError("label") ? "Indikator është i detyruar" : null}
          onChange={handleInputChange}
        />
      </Stack>
      <Stack spacing={2}>
        <TextField
          name="order"
          autoComplete="off"
          id="order"
          variant="outlined"
          type="number"
          InputProps={{
            inputProps: { min: 0 },
            startAdornment: (
              <InputAdornment position="start">Rendi</InputAdornment>
            ),
          }}
          value={state.order}
          error={checkError("order")}
          helperText={checkError("order") ? "Rendi është i detyruar" : null}
          onChange={handleInputChange}
        />
      </Stack>
      {state?.type !== "SELECT" && (
        <Stack spacing={2}>
          <TextField
            name="defaultAnswer"
            autoComplete="off"
            id="defaultAnswer"
            variant="outlined"
            type="number"
            InputProps={{
              inputProps: { min: 0 },
              startAdornment: (
                <InputAdornment position="start">
                  Përgjigje Default
                </InputAdornment>
              ),
            }}
            value={state.defaultAnswer}
            error={checkError("defaultAnswer")}
            helperText={
              checkError("defaultAnswer")
                ? " Përgjigje Default është e detyruar"
                : null
            }
            onChange={handleInputChange}
          />
        </Stack>
      )}
      <Stack spacing={2}>
        <TextField
          name="unit"
          autoComplete="off"
          id="unit"
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">Njësia matëse</InputAdornment>
            ),
          }}
          value={state.unit}
          error={checkError("unit")}
          helperText={
            checkError("unit") ? " Njësia matëse është e detyruar" : null
          }
          onChange={handleInputChange}
        />
      </Stack>
      <Stack spacing={2}>
        <SelectAutocomplate
          name="type"
          id="type"
          label="Tipi"
          placeholder="Kërko Tip"
          noOptionsText="Nuk ka Tip"
          value={state?.type}
          onClick={handleClickType}
          source={INDICATOR_TYPE}
          open={openType}
          anchorEl={anchorElType}
          onClickAway={handleCloseType}
          error={checkError("type")}
          helperText={checkError("type") ? "Tipi është i detyruar" : null}
          onClose={(event, reason) => {
            if (reason === "escape") {
              handleCloseType();
            }
          }}
          onChange={(event, newValue, reason) => {
            if (
              event.type === "keydown" &&
              event.key === "Backspace" &&
              reason === "removeOption"
            ) {
              return;
            }
            setState({
              ...state,
              type: newValue.id ? newValue.id : "",
            });
            handleCloseType();
          }}
          onchangesearch={searchItems}
          inputRef={searchTypeState}
        ></SelectAutocomplate>
      </Stack>
      {state?.type === "SELECT" && (
        <>
          <Stack
            sx={{
              display: "flex",
              width: "100%",
              alignItems: "center",
            }}
          >
            <Button
              variant="outlined"
              endIcon={<Add />}
              sx={{
                alignItems: "center",
                width: "120px",
                display: "flex",
                width: "100%",
                height: "40px",
                color: "#008fc6",
              }}
              onClick={handleOpenOption}
            >
              Alternativat
            </Button>
          </Stack>
          <Stack
            sx={{
              display: "flex",
              width: "100%",
              alignItems: "center",
            }}
          >
            <FormListType allData={state} />
          </Stack>
          <OptionNameModal
            open={openOption}
            onClose={handleCloseOption}
            formname="Alternativa"
            isstatusnew={1}
            data={{}}
            allData={state}
            onGetDataUpdate={handleGetDataUpdate}
          ></OptionNameModal>
        </>
      )}
      {state?.type === "FORMULA" && (
        <>
          <Stack>
            <FormControl fullWidth>
              <TextField
                select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                // value={filterProduct}
                // label="Zgjidh Indikator"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      Zgjidh Indikator
                    </InputAdornment>
                  ),
                }}
                // onChange={handlSelectIndicatorFormula}
                onChange={(event, newValue, reason) => {
                  if (
                    event.type === "keydown" &&
                    event.key === "Backspace" &&
                    reason === "removeOption"
                  ) {
                    return;
                  }
                  const index = listFormula.findIndex(
                    (object) => object.value === event.target.value
                  );

                  if (index === -1) {
                    const updateListFormula = [
                      ...listFormula,
                      {
                        label: newValue?.props?.label,
                        value: event.target.value,
                      },
                    ];
                    setListFormula(updateListFormula);
                  }
                }}
              >
                {formInputs?.map((item) => (
                  <MenuItem key={item.key} label={item.label} value={item.key}>
                    {item?.label}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </Stack>
          <Stack
            sx={{
              display: "flex",
              width: "100%",
              alignItems: "center",
            }}
          >
            {createStringBetweenCharacters(listFormula, "label")}
          </Stack>
          <Box sx={{ display: "flex", flexWrap: "wrap", flexDirection: "row" }}>
            {listFormula.map((item) => {
              return (
                <ListItem
                  sx={{
                    width: "auto",
                    "&.MuiListItem-root": {
                      paddingLeft: "3px",
                      paddingRight: "3px",
                    },
                  }}
                  key={item?.value}
                >
                  <Chip
                    key={item?.value}
                    value={item?.value}
                    label={item?.label}
                    variant="outlined"
                    onDelete={() => DeleteIndicatorFormula(item)}
                    //onClick={handleClick}
                    //onDelete={() => DeleteIndikator(item)}
                  />
                </ListItem>
              );
            })}
          </Box>
        </>
      )}
    </ModalUI>
  );
};
export default FormInputEditor;
