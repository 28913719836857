import * as React from "react";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarExportContainer,
} from "@mui/x-data-grid";
import {
  Button,
  Grid,
  InputLabel,
  Stack,
  TextField,
  Typography,
  MenuItem,
  Select,
  Toolbar,
  Checkbox,
} from "@mui/material";
import { Delete } from "@mui/icons-material";
import gridLabels from "../Labels/GridLabels";
import FORM_INPUTE_COLUMNS from "./FormInputeTableColumn";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../store/loading";
import {
  getAllFormTemplate,
  updateFormTemplate,
} from "../../store/formTemplate";
import { getFormInputesById, deleteFormInput } from "../../store/formInputes";
import StartingPageContent from "../StartingPage/StartingPageContent";
import { Box } from "@mui/system";
import { useLocation, useNavigate } from "react-router-dom";
import { CATEGORY, SHEDULES } from "../../data/dummy-data";
import { getAllUnit } from "../../store/njesiRaportuese";
import FormInputEditor from "./FormInputEditor";
import DialogDelete from "../UI/DialogDelete";
import ButtonAdd from "../UI/ButtonAdd";
import BoxRowButtonsIcon from "../UI/BoxRowButtonsIcon";
import RowButtonIcon from "../UI/RowButtonIcon";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import EditIcon from "@mui/icons-material/Edit";
import BottomToolbar from "../UI/BottomToolbar";
import SelectAutocomplate from "../UI/SelectAutocomplate";
import { getAllFormCategory } from "../../store/formCategory";

const FormTemplateEditor = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const initialForm = {
    id: "",
    name: "",
    description: "",
    reportingUnit: {},
    schedule: "",
    status: "INACTIVE",
    formCategory: {},
    variableSubmission: false,
  };

  const { formTemplate, currentPage, searchValueCurrent } = useSelector(
    (state) => state.formTemplate
  );
  const { njesiRaportuese } = useSelector((state) => state.njesiRaportuese);
  const { formCategory } = useSelector((state) => state.formCategory);
  const { formInputesById } = useSelector((state) => state.formInputesById);

  const stateData = location.state.formTemplate;
  const { message } = useSelector((state) => state.message);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };
  const [isStatusNew, setIsStatusNew] = React.useState(false);
  const [forma, setForma] = React.useState(initialForm);
  const [input, setInput] = React.useState({});
  const [companyCategory, setCompanyCategory] = React.useState("");
  const [stateDialogDelete, setStateDialogDelete] = React.useState(false);
  const [selectRow, setSelectRow] = React.useState("");
  const [dataColumns, setDataColumns] = React.useState(FORM_INPUTE_COLUMNS);
  const [errorValues, setErrorValues] = React.useState([]);

  const searchStateNjesia = React.useRef("");
  const searchShedulesState = React.useRef("");
  const searchFormCategoryState = React.useRef("");
  const [searchValueNjesiaServer, setSearchValueNjesiaServer] =
    React.useState("");
  const [anchorElNjesia, setAnchorElNjesia] = React.useState(null);
  const [anchorElShedules, setAnchorElShedules] = React.useState(null);
  const [anchorElFormCategory, setAnchorElFormCategory] = React.useState(null);

  const navigate = useNavigate();

  const handleClickNjesia = (event) => {
    dispatch(getAllUnit({ page: 0, searchValue: "", size: 1000 }));
    setAnchorElNjesia(event.currentTarget);
  };

  const handleCloseNjesia = () => {
    setAnchorElNjesia(null);
  };

  const handleClickShedules = (event) => {
    setAnchorElShedules(event.currentTarget);
  };

  const handleCloseShedules = () => {
    setAnchorElShedules(null);
  };

  const handleClickFormCategory = (event) => {
    dispatch(getAllFormCategory({ page: 0, searchValue: "", size: 1000 }));
    setAnchorElFormCategory(event.currentTarget);
  };

  const handleCloseFormCategory = () => {
    setAnchorElFormCategory(null);
  };

  const openShedules = Boolean(anchorElShedules);
  const openNjesia = Boolean(anchorElNjesia);
  const openFormCategory = Boolean(anchorElFormCategory);

  const searchItems = (e) => {};

  React.useEffect(() => {
    setErrorValues([]);
    if (stateData) {
      dispatch(getAllUnit({ page: 0, searchValue: "", size: 1000 }));
      dispatch(getAllFormCategory({ page: 0, searchValue: "", size: 1000 }));
      setCompanyCategory(
        stateData.reportingUnit ? stateData.reportingUnit : ""
      );
      stateData.reportingUnit = stateData.reportingUnit;
      // delete stateData.reportingUnit;

      // setForma({ ...stateData, status: "INACTIVE" }); ask for this

      setForma({ ...stateData });
    } else {
      setForma(initialForm);
    }
  }, [stateData]);

  React.useEffect(() => {
    getAllData();
    // dispatch(getAllUnit());
    dispatch(getFormInputesById({ id: stateData?.id }));
  }, [dispatch]);

  const getAllData = () => {
    dispatch(setLoading(true));
    dispatch(getAllFormTemplate())
      .unwrap()
      .then(() => {
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };

  const searchItemsNjesia = (e) => {};

  const [filterModel, setFilterModel] = React.useState({
    items: [
      {
        columnField: "",
        operatorValue: ">",
        value: "",
      },
    ],
  });

  const initialInput = {
    id: "",
    formId: stateData.id ? stateData.id : "",
    key: "",
    label: "",
    order: 0,
    defaultAnswer: "",
    unit: "cope",
    properties: null,
  };
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "companyCategory") {
      setCompanyCategory(value);
    }
    if (name === "variableSubmission") {
      setForma({
        ...forma,
        variableSubmission: event.target?.checked
          ? event.target.checked
          : false,
      });
    } else {
      setForma({ ...forma, [name]: value ? value : "" });
    }
    //setForma({ ...forma, [name]: value ? value : "" });
  };

  const currentlySelected = (e) => {
    setIsStatusNew(false);
    setInput(e.row);
    setOpen(true);
  };

  const addNew = () => {
    setIsStatusNew(true);
    setInput(initialInput);
    setOpen(true);
  };

  const handleClickDelete = (e) => {
    const deleteRow = { formId: stateData?.id, id: e.row?.id };
    setSelectRow(deleteRow);
    setStateDialogDelete(true);
  };

  const handleCloseDialogDelete = (e) => {
    setStateDialogDelete(false);
  };
  const handleDeleteFormTemplate = (e) => {
    dispatch(deleteFormInput({ formInput: selectRow }));
    setStateDialogDelete(false);
  };

  const saveForm = () => {
    var initialError = [];
    Object.keys(forma).map(function (key, index) {
      if (forma[key] !== "" || Object.keys(forma[key]).length !== 0) {
      } else {
        initialError.push(key);
      }
    });

    setErrorValues(initialError);

    if (initialError.length === 0) {
      dispatch(
        updateFormTemplate({
          formTemplate: forma,
          currentPage: currentPage,
          searchValueCurrent: searchValueCurrent,
        })
      );
      onBackNavigation();
    }
  };

  const onBackNavigation = () => {
    navigate(-1);
  };

  const checkError = (item) => {
    if (errorValues.find((element) => element === item)) {
      return true;
    } else {
      return false;
    }
  };
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport
          printOptions={{ disableToolbarButton: true }}
          csvOptions={{
            fileName: "Indikator",
          }}
        ></GridToolbarExport>
        <Box sx={{ display: "flex", justifyContent: "flex-end", width: 1 }}>
          <ButtonAdd onClick={addNew} />
        </Box>
      </GridToolbarContainer>
    );
  }
  return (
    <StartingPageContent back onClick={onBackNavigation} pageName="Form Editor">
      <Box sx={{ mb: 5 }}>
        <Grid
          container
          spacing={{ xs: 2, md: 2 }}
          columns={{ xs: 1, sm: 8, md: 12, lg: 16 }}
        >
          <Grid item xs={2} sm={4} md={4}>
            <Stack spacing={2}>
              <InputLabel>Titulli Formës</InputLabel>
              <TextField
                name="name"
                autoComplete="off"
                id="outlined-basic"
                variant="outlined"
                onChange={handleInputChange}
                value={forma.name}
                error={checkError("name")}
                helperText={
                  checkError("name") ? "Titulli Formës është i detyruar" : null
                }
              />
            </Stack>
          </Grid>
          <Grid item xs={2} sm={4} md={4}>
            <Stack spacing={2}>
              <InputLabel>Përshkrimi</InputLabel>
              <TextField
                name="description"
                autoComplete="off"
                id="description"
                variant="outlined"
                onChange={handleInputChange}
                value={forma.description}
                error={checkError("description")}
                helperText={
                  checkError("description")
                    ? "Përshkrimi është i detyruar"
                    : null
                }
              />
            </Stack>
          </Grid>
          <Grid item xs={2} sm={4} md={4}>
            <Stack spacing={2}>
              <InputLabel>Kategori</InputLabel>
              <SelectAutocomplate
                disableTooltip
                textfield
                name="formCategory"
                id="formCategory"
                label="Form Kategori"
                placeholder="Kërko Kategoria"
                noOptionsText="Nuk ka Kategoria"
                value={forma?.formCategory}
                onClick={handleClickFormCategory}
                source={formCategory}
                open={openFormCategory}
                anchorEl={anchorElFormCategory}
                onClickAway={handleCloseFormCategory}
                error={checkError("formCategory")}
                helperText={
                  checkError("formCategory")
                    ? "Kategoria është e detyruar"
                    : null
                }
                onClose={(event, reason) => {
                  if (reason === "escape") {
                    handleCloseShedules();
                  }
                }}
                onChange={(event, newValue, reason) => {
                  if (
                    event.type === "keydown" &&
                    event.key === "Backspace" &&
                    reason === "removeOption"
                  ) {
                    return;
                  }
                  setForma({
                    ...forma,
                    formCategory: newValue.id ? newValue.id : "",
                  });
                  handleCloseFormCategory();
                }}
                onchangesearch={searchItems}
                inputRef={searchFormCategoryState}
              ></SelectAutocomplate>
            </Stack>
          </Grid>
          <Grid item xs={2} sm={4} md={4}>
            <Stack spacing={2}>
              <InputLabel>Frekuenca</InputLabel>
              <SelectAutocomplate
                disableTooltip
                textfield
                name="schedule"
                id="schedule"
                // label="Frekuenca"
                placeholder="Kërko Frekuence"
                noOptionsText="Nuk ka Frekuence"
                value={forma?.schedule}
                onClick={handleClickShedules}
                source={SHEDULES}
                open={openShedules}
                anchorEl={anchorElShedules}
                onClickAway={handleCloseShedules}
                error={checkError("schedule")}
                helperText={
                  checkError("schedule") ? "Frekuenc është e detyruar" : null
                }
                onClose={(event, reason) => {
                  if (reason === "escape") {
                    handleCloseShedules();
                  }
                }}
                onChange={(event, newValue, reason) => {
                  if (
                    event.type === "keydown" &&
                    event.key === "Backspace" &&
                    reason === "removeOption"
                  ) {
                    return;
                  }
                  setForma({
                    ...forma,
                    schedule: newValue.id ? newValue.id : "",
                  });
                  handleCloseShedules();
                }}
                // onchangesearch={searchItems}
                inputRef={searchShedulesState}
              ></SelectAutocomplate>
            </Stack>
          </Grid>
          <Grid item xs={2} sm={4} md={4}>
            <Stack spacing={2}>
              <InputLabel>Drejtoria</InputLabel>
              <SelectAutocomplate
                disableTooltip
                textfield
                name="reportingUnit"
                id="reportingUnit"
                placeholder="Kërko Drejtori"
                noOptionsText="Nuk ka Drejtori"
                value={forma?.reportingUnit}
                error={checkError("reportingUnit")}
                helperText={
                  checkError("reportingUnit")
                    ? "Drejtoria është e detyruar"
                    : null
                }
                onClick={handleClickNjesia}
                source={njesiRaportuese}
                open={openNjesia}
                anchorEl={anchorElNjesia}
                onClickAway={handleCloseNjesia}
                onClose={(event, reason) => {
                  if (reason === "escape") {
                    handleCloseNjesia();
                  }
                }}
                onChange={(event, newValue, reason) => {
                  if (
                    event.type === "keydown" &&
                    event.key === "Backspace" &&
                    reason === "removeOption"
                  ) {
                    return;
                  }
                  setCompanyCategory(newValue.id ? newValue.id : "");
                  setForma({
                    ...forma,
                    reportingUnit: newValue.id ? newValue.id : "",
                  });
                  handleCloseNjesia();
                }}
                onchangesearch={searchItemsNjesia}
                inputRef={searchStateNjesia}
              ></SelectAutocomplate>
            </Stack>
          </Grid>
          <Grid item xs={2} sm={4} md={4}>
            <Stack spacing={2}>
              <Stack>
                <InputLabel sx={{ height: "50px" }}> </InputLabel>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography variant="h5" color="primary">
                    Lejo rikrijimin e formës
                  </Typography>
                  <Checkbox
                    name="variableSubmission"
                    id="variableSubmission"
                    defaultChecked={false}
                    checked={forma?.variableSubmission}
                    onChange={handleInputChange}
                    // {...props}
                  ></Checkbox>
                </Box>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Box>
      <div style={{ height: 700, width: "100%" }}>
        <Typography sx={{ mb: 2 }} variant="h5">
          Indikatorët
        </Typography>
        <DataGrid
          labelRowsPerPage="Artikuj per faqe"
          disableSelectionOnClick
          columns={[
            ...dataColumns,
            {
              headerName: "",
              overflow: "hidden",
              field: "editim",
              editable: false,
              flex: 1,
              justifyContent: "flex-end",
              disableClickEventBubbling: true,
              align: "right",
              background: "red",
              headers: false,
              renderHeader: () => {},
              renderCell: (params) => (
                <BoxRowButtonsIcon>
                  <RowButtonIcon
                    onClick={(event) => {
                      currentlySelected(params);
                    }}
                  >
                    <RemoveRedEyeOutlinedIcon />
                  </RowButtonIcon>
                  <RowButtonIcon
                    onClick={(event) => {
                      currentlySelected(params);
                    }}
                  >
                    <EditIcon />
                  </RowButtonIcon>
                  <RowButtonIcon
                    delete
                    onClick={(event) => {
                      handleClickDelete(params);
                    }}
                  >
                    <Delete />
                  </RowButtonIcon>
                </BoxRowButtonsIcon>
              ),
            },
          ]}
          rows={formInputesById}
          density="comfortable"
          editMode="row"
          disableColumnMenu
          localeText={gridLabels}
          componentsProps={{
            pagination: {
              labelRowsPerPage: "Rreshta per Faqe",
              showLastButton: true,
              showFirstButton: true,
            },
          }}
          components={{
            Toolbar: CustomToolbar,
          }}
          onRowDoubleClick={currentlySelected}
          filterModel={filterModel}
          onFilterModelChange={(newFilterModel) =>
            setFilterModel(newFilterModel)
          }
        />
        <DialogDelete
          open={stateDialogDelete}
          disagreedelete={handleCloseDialogDelete}
          agreedelete={handleDeleteFormTemplate}
          description=" Jeni të sigurt që doni ta fshini këtë rekord?"
        />
        <FormInputEditor
          open={open}
          formid={stateData?.id}
          onClose={handleClose}
          formname="Form Indikator"
          isstatusnew={isStatusNew ? 1 : 0}
          data={input}
          formInputs={formInputesById ? formInputesById : []}
        ></FormInputEditor>
      </div>
      <Toolbar></Toolbar>
      <BottomToolbar>
        <Button
          variant="contained"
          sx={{
            width: "200px",
            backgroundColor: "#CD3728",
            height: "52px",
          }}
          color="error"
          onClick={onBackNavigation}
        >
          Anullo
        </Button>
        <Button
          variant="contained"
          sx={{
            width: "200px",
            backgroundColor: "primary.dark",
            height: "52px",
          }}
          onClick={saveForm}
        >
          Ruaj
        </Button>
      </BottomToolbar>
    </StartingPageContent>
  );
};

export default FormTemplateEditor;
