import axios from "axios";
import authHeader from "./auth-header";
import { APP_URL } from "./http-common";

const URLEntityName = APP_URL + "dashboard-message";

async function createMessage(message) {
  return axios
    .post(URLEntityName, message, {
      headers: authHeader(),
    })
    .then((response) => {
      return response;
    });
}

const dashbardMessageData = {
  createMessage,
};
export default dashbardMessageData;
