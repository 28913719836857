import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Switch } from "@mui/material";
import gridLabels from "../Labels/GridLabels";
import ProductRangeEditor from "./ProductRangeEditor";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../store/loading";
import { Box } from "@mui/system";
import RowButtonIcon from "../UI/RowButtonIcon";
import EditIcon from "@mui/icons-material/Edit";
import ButtonAdd from "../UI/ButtonAdd";
import BoxRowButtonsIcon from "../UI/BoxRowButtonsIcon";
import DialogDelete from "../UI/DialogDelete";
import SearchInput from "../UI/SearchInput";
import { getProductRange, deleteProductRange } from "../../store/productRange";
import { DeleteOutline } from "@mui/icons-material";

const ProductRangeTable = () => {
  const dispatch = useDispatch();
  const {
    productRange,
    totalElements,
    totalPages,
    size,
    currentPage,
    searchValueCurrent,
  } = useSelector((state) => state.productRange);

  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);
  const [stateDialogDelete, setStateDialogDelete] = React.useState(false);
  const [isStatusNew, setIsStatusNew] = React.useState(false);
  const [njesi, setNjesi] = React.useState({});
  const [dataColumns, setDataColumns] = React.useState([
    {
      field: "name",
      headerName: "Emër",
      width: 500,
      minWidth: 500,
      flex: 1,
      editable: false,
    },
  ]);
  const [page, setPage] = React.useState(currentPage);
  const searchState = React.useRef("");
  const [searchValueServer, setSearchValueServer] =
    React.useState(searchValueCurrent);
  const [isExporting, setIsExporting] = React.useState(false);

  const initialNjesi = {
    id: "",
    name: "",
  };

  const handleChangePage = (event, newPage) => {
    setPage(event);
  };

  const searchItems = (e) => {
    const searchValue = e.target.value;
    if (searchValue !== "") {
      searchState.current.value = searchValue;
      let filterTimeout;
      filterTimeout = setTimeout(() => {
        setSearchValueServer(searchState.current.value);
      }, 1000);
    } else {
      setSearchValueServer("");
      //   dispatch(getAllFormCategory({ page: page, searchValue: "" }));
      dispatch(getProductRange({ page: page, searchValue: "" }));
    }
  };

  React.useEffect(() => {
    getAllData();
  }, [dispatch, page, searchValueServer]);

  const getAllData = () => {
    dispatch(setLoading(true));
    // dispatch(getAllFormCategory({ page: page, searchValue: searchValueServer }))
    dispatch(getProductRange({ page: page, searchValue: searchValueServer }))
      .unwrap()
      .then(() => {
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };

  const [filterModel, setFilterModel] = React.useState({
    items: [
      {
        columnField: "",
        operatorValue: ">",
        value: "",
      },
    ],
  });

  const currentlySelected = (e) => {
    setIsStatusNew(false);
    setNjesi(e.row);
    setOpen(true);
  };

  const addNew = () => {
    setIsStatusNew(true);
    setNjesi(initialNjesi);
    setOpen(true);
  };

  const handleCloseDialogDelete = (e) => {
    setStateDialogDelete(false);
  };
  const handleDeleteProduktRange = (e) => {
    dispatch(
      deleteProductRange({
        id: njesi.id,
        page: page,
        searchValue: searchValueServer,
      })
    );
    setStateDialogDelete(false);
  };

  function CustomToolbar() {
    return (
      <Box
        sx={{
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            py: "18px",
            px: "9px",
            alignItems: "center",
            bgcolor: "transparent",
          }}
        >
          <Box
            sx={{
              flexGrow: 1,
              textAlign: "center",
              direction: "column",
              justifyContent: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {/* <SearchInput
              onChange={searchItems}
              inputRef={searchState}
              defaultValue={searchState.current.value}
            ></SearchInput> */}
          </Box>
          <ButtonAdd onClick={addNew} />
        </Box>
      </Box>
    );
  }
  return (
    <div style={{ height: 700, width: "100%" }}>
      <DataGrid
        labelRowsPerPage="Artikuj per faqe"
        disableSelectionOnClick
        columns={[
          ...dataColumns,
          {
            headerName: "",
            overflow: "hidden",
            field: "id",
            editable: false,
            flex: 1,
            justifyContent: "flex-end",
            disableClickEventBubbling: true,
            align: "right",
            background: "red",
            headers: false,
            renderHeader: () => {},
            renderCell: (params) => (
              <BoxRowButtonsIcon>
                <RowButtonIcon
                  onClick={(event) => {
                    currentlySelected(params);
                  }}
                >
                  <EditIcon />
                </RowButtonIcon>
                <RowButtonIcon
                  onClick={(event) => {
                    setNjesi(params?.row);
                    setStateDialogDelete(true);
                  }}
                >
                  <DeleteOutline color="error" />
                </RowButtonIcon>
              </BoxRowButtonsIcon>
            ),
          },
        ]}
        rows={productRange ? productRange : []}
        density="comfortable"
        editMode="row"
        disableColumnMenu
        localeText={gridLabels}
        componentsProps={{
          pagination: {
            labelRowsPerPage: "Rreshta për Faqe",
            showLastButton: true,
            showFirstButton: true,
          },
        }}
        components={{
          Toolbar: CustomToolbar,
        }}
        // onCellClick={currentlySelected}
        filterModel={filterModel}
        onFilterModelChange={(newFilterModel) => setFilterModel(newFilterModel)}
        page={page}
        onPageChange={handleChangePage}
        count={totalPages}
        rowCount={totalElements}
        pageSize={size > 100 ? 100 : size}
        pagination
        paginationMode="server"
      />
      <DialogDelete
        open={stateDialogDelete}
        disagreedelete={handleCloseDialogDelete}
        agreedelete={handleDeleteProduktRange}
        description="Jeni të sigurt që doni ta fshini këtë Nivel Tensioni? "
      />
      <ProductRangeEditor
        open={open}
        onClose={handleClose}
        formname="Nivel Tensioni"
        isstatusnew={isStatusNew ? 1 : 0}
        data={njesi}
      ></ProductRangeEditor>
    </div>
  );
};

export default ProductRangeTable;
