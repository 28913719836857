import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import RowButtonIcon from "../UI/RowButtonIcon";
import { DeleteOutline } from "@mui/icons-material";
import OptionNameModal from "./OptionNameModal";

export default function FormListType(props) {
  const [checked, setChecked] = React.useState([1]);
  const [open, setOpen] = React.useState(false);
  const { allData } = props;
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };
  const [isStatusNew, setIsStatusNew] = React.useState(false);
  const [isDeleteStatus, setIsDeleteNew] = React.useState(false);

  const [njesi, setNjesi] = React.useState({});
  const [allDataState, setAllDataState] = React.useState(allData);

  const currentlySelected = (dataValue) => {
    setIsDeleteNew(false);
    setIsStatusNew(false);
    setNjesi(dataValue);
    setOpen(true);
  };
  React.useEffect(() => {
    setAllDataState(allData);
  }, [allData]);

  const handleGetDataUpdate = (dataTemp) => {
    setAllDataState(dataTemp);
  };
  return (
    <>
      <List dense sx={{ width: "100%", bgcolor: "background.paper" }}>
        {allDataState?.properties?.options.map((item) => {
          const labelId = `checkbox-list-secondary-label-${item?.key}`;
          return (
            <ListItem
              key={item?.id}
              secondaryAction={
                <RowButtonIcon
                  onClick={(event) => {
                    handleOpen();
                    setNjesi(item);
                    setIsDeleteNew(true);
                    // setNjesi(params?.row);
                    // setStateDialogDelete(true);
                  }}
                >
                  <DeleteOutline color="error" />
                </RowButtonIcon>
              }
              disablePadding
            >
              <ListItemButton onClick={() => currentlySelected(item)}>
                <ListItemAvatar>
                  <Avatar
                    alt={`Avatar n°${item?.key + 1}`}
                    src={`/static/images/avatar/${item + 1}.jpg`}
                  />
                </ListItemAvatar>
                <ListItemText id={labelId} primary={`${item?.value}`} />
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>

      <OptionNameModal
        open={open}
        allData={allDataState}
        onClose={handleClose}
        formname="Alternativa"
        isstatusnew={isStatusNew ? 1 : 0}
        delete={isDeleteStatus ? 1 : 0}
        data={njesi}
        onGetDataUpdate={handleGetDataUpdate}
      ></OptionNameModal>
    </>
  );
}
