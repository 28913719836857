import React from "react";
import { Button, IconButton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import classes from "./StartingPageContent.module.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import MessageIcon from "@mui/icons-material/Message";
import CreateMessageModal from "./CreateMessageModal";

const StartingPageContent = (props) => {
  const location = useLocation();
  const navigate = useNavigate();

  const user = useSelector((state) => state.auth.user);
  const isAdmin = user?.role === "ROLE_EXECUTIVE";
  const [openCreateMessageModal, setOpenCreateMessageModal] =
    React.useState(false);

  const handleOpenCreateMessageModal = () => {
    if (openCreateMessageModal) {
      setOpenCreateMessageModal(false);
    } else {
      setOpenCreateMessageModal(true);
    }
  };
  const onBackNavigation = () => {
    navigate(-1);
  };
  return (
    <div className={classes.starting}>
      <Box>
        {props.back ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              textAlign: "left",
              alignSelf: "left",
              marginLeft: props.back ? "-40px" : 0,
            }}
          >
            <IconButton
              onClick={props.onClick ? props.onClick : onBackNavigation}
              sx={{ color: "#5c56a4" }}
            >
              <ArrowBackIcon />
            </IconButton>
            <Typography
              variant="h5"
              sx={{ color: "#808EB1", fontSize: "16px" }}
            >
              ERE Platforme | {props.pageName}
            </Typography>
          </Box>
        ) : null}
        <Typography variant="h3">{props.pageName}</Typography>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h5" sx={{ mt: "10px", color: "#979797" }}>
            Përshëndetje, Mirësevini në ERE Platforme!
          </Typography>
          {isAdmin && (
            <Button
              variant="outlined"
              sx={{
                margin: "0px 10px",
                height: "32px",
                minWidth: "100px",
                padding: "10",
                fontSize: "14px",
                color: "#008fc6",
              }}
              disableRipple
              endIcon={<MessageIcon />}
              onClick={handleOpenCreateMessageModal}
            >
              Krijo Mesazh
            </Button>
          )}
        </Box>
      </Box>
      <CreateMessageModal
        open={openCreateMessageModal}
        onClose={handleOpenCreateMessageModal}
      ></CreateMessageModal>
      <Box sx={{ py: "45px" }}> {props.children}</Box>
    </div>
  );
};

export default StartingPageContent;
