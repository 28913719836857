import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";
import { setLoading } from "./loading";
import ProductRangeService from "../services/productRange.service";

const initialState = {
  productRange: [],
  totalElements: 0,
  totalPages: 0,
  size: 0,
  page: 0,
  currentPage: 0,
  searchValueCurrent: "",
};
export const setSearch = createAsyncThunk(
  "productRange/setSearchValue",
  ({ searchValue }, thunkAPI) => {
    return {
      searchValue: searchValue,
    };
  }
);

export const getProductRange = createAsyncThunk(
  "productRange/get",
  async ({ page, searchValue }, thunkAPI) => {
    // thunkAPI.dispatch(setMessage(""));
    //thunkAPI.rejectWithValue();
    try {
      const data = await ProductRangeService.getAllProductRange(
        page,
        searchValue
      );
      //thunkAPI.dispatch(setLoading(false));
      //  thunkAPI.rejectWithValue();
      return {
        productRange: data,
      };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      // thunkAPI.dispatch(setLoading(false));
      //thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const createProductRange = createAsyncThunk(
  "product/create",
  async ({ product }, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    try {
      const data = await ProductRangeService.createProductRange(product);
      //thunkAPI.dispatch(getAll());
      thunkAPI.dispatch(getProductRange({ page: 0, searchValue: "" }));
      thunkAPI.dispatch(setLoading(false));
      return thunkAPI.rejectWithValue();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setLoading(false));
      thunkAPI.dispatch(setMessage({ message: message }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updateProductRange = createAsyncThunk(
  "product/update",
  async ({ productItem, currentPage, searchValueCurrent }, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    try {
      const data = await ProductRangeService.updateProductRange(productItem);
      thunkAPI.dispatch(
        getProductRange({
          page: currentPage ? currentPage : 0,
          searchValue: searchValueCurrent ? searchValueCurrent : "",
        })
      );
      thunkAPI.dispatch(setLoading(false));
      return thunkAPI.rejectWithValue();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setLoading(false));
      thunkAPI.dispatch(setMessage({ message: message }));
      thunkAPI.dispatch(getProductRange({ page: 0, searchValue: "" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const deleteProductRange = createAsyncThunk(
  "productRange/delete",
  async ({ id, currentPage = 0, searchValueCurrent = "" }, thunkAPI) => {
    try {
      const data = await ProductRangeService.deleteProductRange(id);
      thunkAPI.dispatch(setMessage({ message: "Rekordi u fshi me sukses!" }));
      thunkAPI.dispatch(
        getProductRange({
          page: currentPage ? currentPage : 0,
          searchValue: searchValueCurrent ? searchValueCurrent : "",
        })
      );
      return thunkAPI.rejectWithValue();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message }));
      thunkAPI.dispatch(
        getProductRange({
          page: currentPage ? currentPage : 0,
          searchValue: searchValueCurrent ? searchValueCurrent : "",
        })
      );
      return thunkAPI.rejectWithValue();
    }
  }
);

const productRangeSlice = createSlice({
  name: "productRange",
  initialState,
  extraReducers: {
    [getProductRange.fulfilled]: (state, action) => {
      state.productRange = action.payload.productRange;
      state.totalElements = action.payload.totalElements;
      state.totalPages = action.payload.totalPages;
      state.size = action.payload.size;
      state.searchValue = action.payload.searchValue;
    },
    [getProductRange.rejected]: (state, action) => {
      state.productRange = null;
      state.totalElements = 0;
      state.totalPages = 0;
      state.size = 0;
      state.searchValue = "";
    },
    [setSearch.fulfilled]: (state, action) => {
      state.searchValue = action.payload.searchValue;
    },
    [setSearch.rejected]: (state, action) => {
      state.searchValue = "";
    },
  },
});

const { reducer } = productRangeSlice;
export default reducer;
