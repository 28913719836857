import * as React from "react";
import { Alert, Snackbar } from "@mui/material";

const MessageAlert = (props) => {
  const { open, message, handleClose } = props;
  return (
    <Snackbar
      open={message ? true : false}
      autoHideDuration={4000}
      onClose={handleClose}
      message="Mesazh"
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      sx={{ zIndex: 100 }}
    >
      <Alert
        onClose={handleClose}
        //severity="info"
        icon={false}
        sx={{
          width: "100%",
          backgroundColor: "#7CB0FF",
          color: "#fff",
          borderRadius: "15px",
        }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export default MessageAlert;
