import * as React from "react";
import {
  TextField,
  Button,
  Stack,
  InputAdornment,
  MenuItem,
  Chip,
  InputLabel,
} from "@mui/material";
import { Save } from "@mui/icons-material";
import ModalUI from "../UI/ModalUI";
import { useDispatch, useSelector } from "react-redux";
import { createProduct, getProduct, updateProduct } from "../../store/product";
import { getProductRange } from "../../store/productRange";
import { setLoading } from "../../store/loading";

const FormProduktListModalEditor = (props) => {
  const dispatch = useDispatch();
  const { currentPage, searchValueCurrent } = useSelector(
    (state) => state.njesiRaportuese
  );

  const { productRange } = useSelector((state) => state.productRange);

  const { data, isstatusnew, open } = props;
  const [state, setState] = React.useState({
    name: "",
    ranges: [],
  });
  const [range, setRange] = React.useState("");

  const [rangesSelectState, setRangesSelectState] = React.useState([]);

  const [errorValues, setErrorValues] = React.useState([]);

  React.useEffect(() => {
    setErrorValues([]);
    setState({
      id: data.id ? data.id : "",
      name: data.name ? data.name : "",
      ranges: data.ranges ? data?.ranges?.map((a) => a.id) : [],
    });
    setRangesSelectState(data?.ranges?.map((a) => a.id));
  }, [data, open]);

  React.useEffect(() => {
    getAllDataProductRange();
  }, [dispatch]);

  const getAllDataProductRange = () => {
    dispatch(setLoading(true));
    dispatch(getProductRange({ page: 0, searchValue: "" }))
      .unwrap()
      .then(() => {
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setState({ ...state, [name]: value ? value : "" });
  };

  const saveUser = () => {
    var initialError = [];
    Object.keys(state).map(function (key, index) {
      if (state[key] !== "" || Object.keys(state[key]).length !== 0) {
      } else {
        initialError.push(key);
      }
    });

    setErrorValues(initialError);

    var initialErrorTemp = [];
    initialErrorTemp = initialError.filter((item) => item !== "id"); // remove id item

    if (initialErrorTemp.length === 0) {
      if (isstatusnew) {
        const { id, ...newProduct } = state;
        dispatch(createProduct({ product: newProduct }));
      } else {
        const { ...updateUnitTemp } = state;
        dispatch(
          updateProduct({
            productItem: updateUnitTemp,
            currentPage: currentPage,
            searchValueCurrent: searchValueCurrent,
          })
        );
      }
      dispatch(
        getProduct({
          page: currentPage,
          searchValue: searchValueCurrent,
        })
      );
      props.onClose();
    }
  };

  const checkError = (item) => {
    if (errorValues.find((element) => element === item)) {
      return true;
    } else {
      return false;
    }
  };

  const DeleteRangesSelected = (item) => {
    setRangesSelectState(rangesSelectState.filter((it) => it !== item.id));
    setState({
      ...state,
      ranges: rangesSelectState.filter((it) => it !== item.id),
    });
  };

  return (
    <ModalUI {...props}>
      <Stack spacing={2}>
        <TextField
          name="name"
          autoComplete="off"
          id="name"
          variant="outlined"
          error={checkError("name")}
          helperText={checkError("name") ? "Emri është i detyruar" : null}
          InputProps={{
            startAdornment: (
              <InputAdornment
                sx={{
                  "&.MuiInputAdornment-root": {
                    minWidth: "100px",
                  },
                }}
                position="start"
              >
                Emër
              </InputAdornment>
            ),
          }}
          value={state.name}
          onChange={handleInputChange}
        />
      </Stack>
      <Stack>
        <InputLabel>Niveli i tensionit</InputLabel>
        <TextField
          select
          name="range"
          autoComplete="off"
          id="range"
          variant="outlined"
          required="range"
          value={range}
          onChange={(event, newValue, reason) => {
            if (
              event.type === "keydown" &&
              event.key === "Backspace" &&
              reason === "removeOption"
            ) {
              return;
            }
            setRangesSelectState([...rangesSelectState, newValue.props.value]);
            setState({
              ...state,
              ranges: [...rangesSelectState, newValue.props.value],
            });
            setRange("");
            // handleCloseNjesia();
          }}
          sx={{
            "& .MuiInputBase-root.MuiOutlinedInput-root": {
              height: "20px",
              textAlign: "left",
            },
            "& .MuiInputBase-input.MuiOutlinedInput-input": {
              padding: "12px 14px",
            },
            width: "100%",
          }}
        >
          {productRange?.map((option) => (
            <MenuItem key={option?.name} value={option?.id}>
              {option?.name}
            </MenuItem>
          ))}
        </TextField>
        <div
          style={{
            paddingTop: "10px",
            textAlign: "left",
          }}
        >
          {productRange.map((item) => {
            return (
              rangesSelectState?.includes(item.id) && (
                <Chip
                  key={item.id}
                  value={item.name}
                  label={item.name}
                  variant="outlined"
                  style={{ marginRight: "4px" }}
                  onDelete={() => DeleteRangesSelected(item)}
                />
              )
            );
          })}
        </div>
      </Stack>
      <Stack sx={{ display: "flex", width: "100%" }}>
        <Button
          variant="contained"
          sx={{
            backgroundColor: "primary.dark",
            display: "flex",
            marginTop: "40px",
          }}
          startIcon={<Save />}
          onClick={saveUser}
        >
          {isstatusnew ? "Shto" : "Ndrysho"}
        </Button>
      </Stack>
    </ModalUI>
  );
};
export default FormProduktListModalEditor;
