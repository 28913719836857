import * as React from "react";
import {
  TextField,
  Button,
  Stack,
  InputAdornment,
  MenuItem,
  InputLabel,
  Box,
  Alert,
} from "@mui/material";
import { Save } from "@mui/icons-material";
import ModalUI from "../UI/ModalUI";
import { useDispatch, useSelector } from "react-redux";
import { getProduct } from "../../store/product";
import { getAllCompany } from "../../store/company";
import {
  createLicense,
  getAllLicense,
  updateLicense,
} from "../../store/companyLicense";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import TextFieldDateFilter from "../UI/TextFieldDateFilter";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { id, sq } from "date-fns/locale";
import { getDateFormat } from "../helper/CustomLabel";
import SelectFilter from "../UI/SelectFilter";
import { setLoading } from "../../store/loading";

const CompanyLicenseModalEditor = (props) => {
  const dispatch = useDispatch();
  const { company } = useSelector((state) => state.company);
  const { currentPage, searchValueCurrent, products } = useSelector(
    (state) => state.product
  );

  const { data, isstatusnew, open } = props;
  const [state, setState] = React.useState({
    id: "",
    code: "",
    company: "",
    endDate: "",
    startDate: "",
    description: "",
  });
  const searchStateCompany = React.useRef("");
  const [searchValueServerCompany, setSearchValueServerCompany] =
    React.useState("");
  const [anchorElCompany, setAnchorElCompany] = React.useState(null);
  const [errorValues, setErrorValues] = React.useState([]);
  const openCompany = Boolean(anchorElCompany);

  React.useEffect(() => {
    dispatch(getAllCompany({ search: "" }));
    dispatch(getProduct({ page: 0, searchValue: "" }));
  }, [open]);

  React.useEffect(() => {
    setErrorValues([]);
    setState({
      id: data.id ? data.id : "",
      code: data.code ? data.code : "",
      company: data.company?.id ? data.company?.id : "",
      endDate: data.endDate ? data.endDate : "",
      startDate: data.startDate ? data.startDate : "",
      description: data.description ? data.description : "",
    });
  }, [data, open]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setState({ ...state, [name]: value ? value : "" });
  };

  const saveLicense = () => {
    var initialError = [];
    Object.keys(state).map(function (key, index) {
      if (state[key] !== "" || Object.keys(state[key]).length !== 0) {
      } else {
        initialError.push(key);
      }
    });
    setErrorValues(initialError);

    var initialErrorTemp = [];
    initialErrorTemp = initialError.filter((item) => item !== "id"); // remove id item

    if (initialErrorTemp.length === 0) {
      if (isstatusnew) {
        const { id, ...newLicense } = state;
        // dispatch(createPrice({ price: newPrice }));
        dispatch(createLicense({ companyLicense: newLicense }));
        dispatch(getAllLicense({ page: 0, searchValue: "" }));
      } else {
        const { ...updateCompanyLicenseTemp } = state;
        dispatch(
          updateLicense({
            companyLicense: updateCompanyLicenseTemp,
            currentPage: currentPage,
            searchValueCurrent: searchValueCurrent,
          })
        );
        // dispatch(
        //   updateUnit({
        //     unit: updateUnitTemp,
        //     currentPage: currentPage,
        //     searchValueCurrent: searchValueCurrent,
        //   })
        // );
      }

      // dispatch(getPrice({ page: 0, searchValue: "" })); get all License here
      props.onClose();
    }
  };
  const changeProductId = (e) => {
    setState((prevState) => {
      return {
        ...prevState,
        productId: e.target.value,
      };
    });
  };

  const checkError = (item) => {
    if (errorValues.find((element) => element === item)) {
      return true;
    } else {
      return false;
    }
  };

  const searchItemsCompany = (e) => {
    const searchValue = e.target.value;
    if (searchValue !== "") {
      searchStateCompany.current.value = searchValue;
      let filterTimeout;
      filterTimeout = setTimeout(() => {
        setSearchValueServerCompany(searchStateCompany.current.value);
      }, 1000);
    } else {
      setSearchValueServerCompany("");
      dispatch(getAllCompany({ search: "" }));
    }
  };

  const handleClickCompany = (event) => {
    dispatch(getAllCompany({ search: "" }));
    setAnchorElCompany(event.currentTarget);
  };

  React.useEffect(() => {
    getAllCompanyData();
  }, [dispatch, searchValueServerCompany]);

  const getAllCompanyData = () => {
    dispatch(setLoading(true));
    dispatch(getAllCompany({ search: searchValueServerCompany }))
      .unwrap()
      .then(() => {
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };

  const handleClickStartDate = (event) => {
    setState({
      ...state,
      //dateEnd: "",
    });
  };
  const handleCloseCompany = () => {
    setAnchorElCompany(null);
  };

  return (
    <ModalUI {...props}>
      <Stack spacing={2}>
        <TextField
          sx={{
            "& .MuiInputBase-root.MuiOutlinedInput-root": {
              // height: "100px",
            },
          }}
          multiline
          name="code"
          autoComplete="off"
          id="code"
          variant="outlined"
          error={checkError("code")}
          helperText={checkError("code") ? "Kodi është i detyruar" : null}
          InputProps={{
            startAdornment: (
              <InputAdornment
                sx={{
                  "&.MuiInputAdornment-root.MuiInputAdornment-root": {
                    minWidth: "0px",
                  },
                }}
                position="start"
              >
                Kodi
              </InputAdornment>
            ),
          }}
          value={state.code}
          onChange={handleInputChange}
        />
      </Stack>
      <Stack
        spacing={2}
        sx={{ mr: "10px", paddingBottom: "10px", width: "100%" }}
      >
        <InputLabel>Kompania</InputLabel>
        <SelectFilter
          textfield
          name="company"
          id="company"
          label="Kompani"
          placeholder="Kërko Kompani"
          noOptionsText="Nuk ka Kompani"
          value={state.company}
          onClick={handleClickCompany}
          error={checkError("company")}
          helperText={
            checkError("company") ? "Kompania është e detyruar" : null
          }
          source={company}
          open={openCompany}
          anchorEl={anchorElCompany}
          onClickAway={handleCloseCompany}
          onClose={(event, reason) => {
            if (reason === "escape") {
              handleCloseCompany();
            }
          }}
          onChange={(event, newValue, reason) => {
            if (
              event.type === "keydown" &&
              event.key === "Backspace" &&
              reason === "removeOption"
            ) {
              return;
            }
            setState({
              ...state,
              company: newValue.id ? newValue.id : "",
            });
            handleCloseCompany();
            // getAllDataFormUnit();
          }}
          maxWidth="100%"
          onchangesearch={searchItemsCompany}
          inputRef={searchStateCompany}
          zIndexPopper={2000}
        ></SelectFilter>
      </Stack>
      <Stack spacing={2}>
        <TextField
          name="description"
          autoComplete="off"
          id="description"
          variant="outlined"
          error={checkError("description")}
          helperText={
            checkError("description") ? "Pershkrimi është i detyruar" : null
          }
          InputProps={{
            startAdornment: (
              <InputAdornment
                sx={{
                  "&.MuiInputAdornment-root.MuiInputAdornment-root": {
                    minWidth: "0px",
                  },
                }}
                position="start"
              >
                Pershkrimi
              </InputAdornment>
            ),
          }}
          value={state.description}
          onChange={handleInputChange}
        />
      </Stack>
      <Box
        spacing={2}
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        <Stack spacing={2}>
          <InputLabel required>Datë Fillimi</InputLabel>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={sq}>
            <DatePicker
              value={state.startDate}
              views={["year", "month"]}
              onChange={(newValue) => {
                var first_date = new Date(
                  newValue.getFullYear(),
                  newValue.getMonth(),
                  1
                );
                setState({
                  ...state,
                  startDate: first_date ? getDateFormat(first_date) : "",
                  //dateEnd: "",
                });
              }}
              PopperProps={{
                disablePortal: true,
              }}
              renderInput={(params) => (
                <TextFieldDateFilter
                  maxWidth="170px"
                  inputWidth="100px"
                  error={checkError("startDate")}
                  helperText={
                    checkError("startDate") ? "Data është e detyruar" : null
                  }
                  onClick={handleClickStartDate}
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    placeholder: "muaj viti",
                  }}
                />
              )}
            />
          </LocalizationProvider>
        </Stack>
        <Stack spacing={2}>
          <InputLabel required>Datë Mbarimi</InputLabel>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={sq}>
            <DatePicker
              value={state.endDate}
              views={["year", "month"]}
              onChange={(newValue) => {
                var last_date = new Date(
                  newValue.getFullYear(),
                  newValue.getMonth() + 1,
                  0
                );
                setState({
                  ...state,
                  endDate: last_date ? getDateFormat(last_date) : "",
                });
              }}
              PopperProps={{
                disablePortal: true,
              }}
              renderInput={(params) => (
                <TextFieldDateFilter
                  maxWidth="170px"
                  inputWidth="100px"
                  {...params}
                  error={checkError("endDate")}
                  helperText={
                    checkError("endDate") ? "Data është e detyruar" : null
                  }
                  inputProps={{
                    ...params.inputProps,
                    placeholder: "muaj viti",
                  }}
                />
              )}
            />
          </LocalizationProvider>
        </Stack>
      </Box>
      {errorValues?.length !== 0 && (
        <Box sx={{ paddingTop: "20px" }}>
          <Alert severity="error">Ploteso te gjitha fushat</Alert>
        </Box>
      )}
      <Stack sx={{ display: "flex", width: "100%" }}>
        <Button
          variant="contained"
          sx={{
            backgroundColor: "primary.dark",
            display: "flex",
            marginTop: "20px",
          }}
          startIcon={<Save />}
          onClick={saveLicense}
        >
          {isstatusnew ? "Shto" : "Ndrysho"}
        </Button>
      </Stack>
    </ModalUI>
  );
};
export default CompanyLicenseModalEditor;
