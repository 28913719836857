import NjesiRaportuese from "../models/njesiRaportuese";
import Perdorues from "../models/perdorues";
import Forma from "../models/forma";

export const PERDORUESIT = [
  new Perdorues(1, "enea", "123456", { value: "ROLE_ADMIN", label: "Admin" }),
  new Perdorues(2, "endrit", "123456", {
    value: "ROLE_EDITOR",
    label: "Editor",
  }),
  new Perdorues(3, "enea2", "123456", {
    value: "ROLE_EDITOR",
    label: "Editor",
  }),
  new Perdorues(4, "endrit2", "123456", {
    value: "ROLE_ADMIN",
    label: "Admin",
  }),
];

export const ROLE = [
  { value: "ROLE_OPERATOR", label: "Operator" },
  { value: "ROLE_EDITOR", label: "Editor" },
  { value: "ROLE_ANALYST", label: "Analist" },
  { value: "ROLE_SOFT_EXECUTIVE", label: "Ekzekutiv" },
  // { value: "ROLE_MINOR_EXECUTIVE", label: "Minor Ekzekutiv" },
];

export const USER_TYPE = [
  { value: "AD_USER", label: "Perdorues AD" },
  { value: "SYSTEM_USER", label: "Perdorues Sistemi" },
];

export const STATUS_USERS = [
  { value: "ACTIVE", label: "Aktiv" },
  { value: "INACTIVE", label: "Jo Aktiv" },
  { value: "PENDING", label: "Ne Pritje" },
];

export const NJESITE_RAPORTUESE = [
  new NjesiRaportuese(1, "Njesia e pare"),
  new NjesiRaportuese(2, "Njesia e dyte"),
  new NjesiRaportuese(3, "Njesia e trete"),
];

export const SHEDULES = [
  { id: "WEEKLY", label: "Javore", name: "Javore" },
  { id: "MONTHLY", label: "Mujore", name: "Mujore" },
  { id: "QUARTERLY", label: "Tre mujore", name: "Tre mujore" },
  { id: "ONE_THREE", label: "Kater mujore", name: "Kater mujore" },
  { id: "BIANNUAL", label: "Gjashte mujore", name: "Gjashte mujore" },
  { id: "YEARLY", label: "Vjetore", name: "Vjetore" },
];

export const CATEGORY = [
  {
    id: "SUPPLIER",
    label: "Furnizues",
    name: "Furnizues",
    value: "SUPPLIER",
    description: "furnizues",
  },
  {
    id: "TRANSMITTER",
    label: "Transmetues",
    name: "Transmetues",
    value: "TRANSMITTER",
    description: "transmetues",
  },
  {
    id: "MANUFACTURER",
    label: "Prodhues",
    name: "Prodhues",
    value: "MANUFACTURER",
    description: "prodhues",
  },
  {
    id: "DISTRIBUTOR",
    label: "Shperndares",
    name: "Shperndares",
    value: "DISTRIBUTOR",
    description: "shperndares",
  },
];

export const FORMAT = [new Forma(1, "testForm", "nje here ne muaj", "draft")];

export const STATUS_PRODUCT = [
  { value: "APPROVED", label: "Konfirmuar" },
  { value: "REJECTED", label: "Refuzuar" },
  { value: "PENDING", label: "Ne Pritje" },
];

export const INDICATOR_TYPE = [
  {
    id: "NUMBER",
    label: "Numer",
    name: "Numer",
    value: "NUMBER",
  },
  {
    id: "TEXT",
    label: "Tekst",
    name: "Tekst",
    value: "TEXT",
  },
  {
    id: "DOC",
    label: "Dokument",
    name: "Dokument",
    value: "DOC",
  },
  {
    id: "DATE",
    label: "Date",
    name: "Date",
    value: "DATE",
  },
  {
    id: "SELECT",
    label: "List",
    name: "List",
    value: "SELECT",
  },
  {
    id: "FORMULA",
    label: "Formula",
    name: "Formula",
    value: "FORMULA",
  },
];
