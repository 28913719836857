import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";
import { setLoading } from "./loading";
import CompanyLicense from "../services/companyLicense.service";

const initialState = {
  companyLicense: [],
  totalElements: 0,
  totalPages: 0,
  size: 10,
  page: 0,
  searchValue: "",
  currentPage: 0,
  searchValueCurrent: "",
};

export const setSearch = createAsyncThunk(
  "company-License/setSearchValue",
  ({ searchValue }, thunkAPI) => {
    return {
      searchValue: searchValue,
    };
  }
);
export const getAllLicense = createAsyncThunk(
  "company-license/getAllCompanyLicense",
  async ({ page, searchValue, size, filterSelect = false }, thunkAPI) => {
    try {
      const data = await CompanyLicense.getAllCompanyLicense(
        page,
        searchValue,
        size,
        filterSelect
      );
      return {
        companyLicense: data.content,
        totalElements: data.totalElements,
        totalPages: data.totalPages,
        size: data.size,
        currentPage: data.number,
        searchValueCurrent: searchValue,
      };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      // thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const createLicense = createAsyncThunk(
  "company-license/create",
  async ({ companyLicense }, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    try {
      const data = await CompanyLicense.createCompanyLicense(companyLicense);
      thunkAPI.dispatch(getAllLicense({ page: 0, searchValue: "" }));
      thunkAPI.dispatch(setLoading(false));
      return thunkAPI.rejectWithValue();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setLoading(false));
      thunkAPI.dispatch(setMessage({ message: message }));
      thunkAPI.dispatch(getAllLicense({ page: 0, searchValue: "" }));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const updateLicense = createAsyncThunk(
  "company-license/update",
  async ({ companyLicense, currentPage, searchValueCurrent }, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    try {
      const data = await CompanyLicense.updateCompanyLicense(companyLicense);
      thunkAPI.dispatch(
        getAllLicense({
          page: currentPage ? currentPage : 0,
          searchValue: searchValueCurrent ? searchValueCurrent : "",
        })
      );
      // thunkAPI.dispatch(getAllUnit({ page: 0, searchValue: "" }));
      thunkAPI.dispatch(setLoading(false));
      return thunkAPI.rejectWithValue();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setLoading(false));
      thunkAPI.dispatch(setMessage({ message: message }));
      thunkAPI.dispatch(getAllLicense({ page: 0, searchValue: "" }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const deleteLicense = createAsyncThunk(
  "company-license/delete",
  async ({ id, currentPage = 0, searchValueCurrent = "" }, thunkAPI) => {
    try {
      const data = await CompanyLicense.deleteCompanyLicense(id);
      thunkAPI.dispatch(setMessage({ message: "Rekordi u fshi me sukses!" }));
      thunkAPI.dispatch(
        getAllLicense({
          page: currentPage ? currentPage : 0,
          searchValue: searchValueCurrent ? searchValueCurrent : "",
        })
      );
      return thunkAPI.rejectWithValue();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({ message: message }));
      thunkAPI.dispatch(
        getAllLicense({
          page: currentPage ? currentPage : 0,
          searchValue: searchValueCurrent ? searchValueCurrent : "",
        })
      );
      return thunkAPI.rejectWithValue();
    }
  }
);

const companyLicenseSlice = createSlice({
  name: "companyLicense",
  initialState,
  extraReducers: {
    [getAllLicense.fulfilled]: (state, action) => {
      state.companyLicense = action.payload.companyLicense;
      state.totalElements = action.payload.totalElements;
      state.totalPages = action.payload.totalPages;
      state.size = action.payload.size;
      state.searchValue = action.payload.searchValue;
      state.currentPage = action.payload.currentPage;
      state.searchValueCurrent = action.payload.searchValueCurrent;
    },
    [getAllLicense.rejected]: (state, action) => {
      state.njesiRaportuese = null;
      state.totalElements = 0;
      state.totalPages = 0;
      state.size = 10;
      state.searchValue = "";
    },
    [setSearch.fulfilled]: (state, action) => {
      state.searchValue = action.payload.searchValue;
    },
    [setSearch.rejected]: (state, action) => {
      state.searchValue = "";
    },
  },
});

const { reducer } = companyLicenseSlice;
export default reducer;
