import ProductRangeTable from "../components/ProductRange/ProductRangeTable";
import StartingPageContent from "../components/StartingPage/StartingPageContent";
const ProductRangePage = () => {
  return (
    <StartingPageContent pageName="Nivel i Tensionit">
      <ProductRangeTable></ProductRangeTable>
    </StartingPageContent>
  );
};

export default ProductRangePage;
